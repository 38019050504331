import FrameImage5 from "../assets/printsAssets/Frame 5.png";
import FrameImage6 from "../assets/printsAssets/Frame 6.png";
import FrameImage7 from "../assets/printsAssets/Frame 7.png";
import FrameImage9 from "../assets/printsAssets/Frame 9.png";
import FrameImage10 from "../assets/printsAssets/Frame 10.png";
import FrameImage14 from "../assets/printsAssets/Frame 14.png";
import FrameImage16 from "../assets/printsAssets/Frame 16.png";
import FrameImage17 from "../assets/printsAssets/Frame 17.png";
import FrameImage18 from "../assets/printsAssets/Frame 18.png";

import FrameImage20 from "../assets/printsAssets/Frame 20.png";
import FrameImage21 from "../assets/printsAssets/Frame 21.png";
import FrameImage22 from "../assets/printsAssets/Frame 22.png";
import FrameImage23 from "../assets/printsAssets/Frame 23.png";
import FrameImage24 from "../assets/printsAssets/Frame 24.png";
import FrameImage26 from "../assets/printsAssets/Frame 26.png";
import FrameImage27 from "../assets/printsAssets/Frame 27.png";
import FrameImage28 from "../assets/printsAssets/Frame 28.png";
import FrameImage29 from "../assets/printsAssets/Frame 29.png";
import FrameImage33 from "../assets/printsAssets/Frame 33.png";
import FrameImage34 from "../assets/printsAssets/Frame 34.png";
import FrameImage35 from "../assets/printsAssets/Frame 35.png";
import FrameImage36 from "../assets/printsAssets/Frame 36.png";
import FrameImage37 from "../assets/printsAssets/Frame 37.png";
import FrameImage63 from "../assets/printsAssets/Frame 63.png";
import FrameImage65 from "../assets/printsAssets/Frame 65.png";
import FrameImage66 from "../assets/printsAssets/Frame 66.png";
import FrameImage67 from "../assets/printsAssets/Frame 67.png";
import FrameImage68 from "../assets/printsAssets/Frame 68.png";
import FrameImage69 from "../assets/printsAssets/Frame 69.png";
import FrameImage70 from "../assets/printsAssets/Frame 70.png";
import FrameImage71 from "../assets/printsAssets/Frame 71.png";
import FrameImage72 from "../assets/printsAssets/Frame 72.png";
import FrameImage73 from "../assets/printsAssets/Frame 73.png";
import FrameImage74 from "../assets/printsAssets/Frame 74.png";
import FrameImage75 from "../assets/printsAssets/Frame 75.png";
import FrameImage76 from "../assets/printsAssets/Frame 76.png";
import FrameImage77 from "../assets/printsAssets/Frame 77.png";
import FrameImage78 from "../assets/printsAssets/Frame 78.png";
import FrameImage79 from "../assets/printsAssets/Frame 79.png";
import FrameImage81 from "../assets/printsAssets/Frame 81.png";
import FrameImage82 from "../assets/printsAssets/Frame 82.png";
import FrameImage83 from "../assets/printsAssets/Frame 83.png";
import FrameImage84 from "../assets/printsAssets/Frame 84.png";
import FrameImage85 from "../assets/printsAssets/Frame 85.png";
import FrameImage86 from "../assets/printsAssets/Frame 86.png";
import FrameImage87 from "../assets/printsAssets/Frame 87.png";
import FrameImage88 from "../assets/printsAssets/Frame 88.png";
import FrameImage89 from "../assets/printsAssets/Frame 89.png";
import FrameImage90 from "../assets/printsAssets/Frame 90.png";
import FrameImage91 from "../assets/printsAssets/Frame 91.png";
import FrameImage92 from "../assets/printsAssets/Frame 92.png";
import FrameImage93 from "../assets/printsAssets/Frame 93.png";
import FrameImage94 from "../assets/printsAssets/Frame 94.png";
import FrameImage95 from "../assets/printsAssets/Frame 95.png";
import FrameImage96 from "../assets/printsAssets/Frame 96.png";
import FrameImage97 from "../assets/printsAssets/Frame 97.png";
import FrameImage98 from "../assets/printsAssets/Frame 98.png";
import FrameImage99 from "../assets/printsAssets/Frame 99.png";
import FrameImage100 from "../assets/printsAssets/Frame 100.png";
import FrameImage101 from "../assets/printsAssets/IMG_2103.png";

export const images = [
  { id: "t-shirt-32", img: FrameImage6 },
  { id: "t-shirt-32", img: FrameImage5 },
  { id: "t-shirt-32", img: FrameImage7 },
  { id: "t-shirt-32", img: FrameImage9 },
  { id: "t-shirt-32", img: FrameImage10 },
  { id: "t-shirt-32", img: FrameImage14 },
  { id: "t-shirt-32", img: FrameImage16 },
  { id: "t-shirt-32", img: FrameImage17 },
  { id: "t-shirt-32", img: FrameImage20 },
  { id: "t-shirt-32", img: FrameImage21 },
  { id: "t-shirt-32", img: FrameImage22 },
  { id: "t-shirt-32", img: FrameImage23 },
  { id: "t-shirt-32", img: FrameImage24 },
  { id: "t-shirt-32", img: FrameImage26 },
  { id: "t-shirt-32", img: FrameImage27 },
  { id: "t-shirt-32", img: FrameImage28 },
  { id: "t-shirt-32", img: FrameImage33 },
  { id: "t-shirt-32", img: FrameImage34 },
  { id: "t-shirt-32", img: FrameImage35 },
  { id: "t-shirt-32", img: FrameImage36 },
  { id: "t-shirt-32", img: FrameImage37 },
  { id: "t-shirt-32", img: FrameImage63 },
  { id: "t-shirt-32", img: FrameImage65 },
  { id: "t-shirt-32", img: FrameImage66 },
  { id: "t-shirt-32", img: FrameImage67 },
  { id: "t-shirt-32", img: FrameImage68 },
  { id: "t-shirt-32", img: FrameImage69 },
  { id: "t-shirt-32", img: FrameImage70 },
  { id: "t-shirt-32", img: FrameImage71 },
  { id: "t-shirt-32", img: FrameImage72 },
  { id: "t-shirt-32", img: FrameImage73 },
  { id: "t-shirt-32", img: FrameImage74 },
  { id: "t-shirt-32", img: FrameImage75 },
  { id: "t-shirt-32", img: FrameImage76 },
  { id: "t-shirt-32", img: FrameImage77 },
  { id: "t-shirt-32", img: FrameImage78 },
  { id: "t-shirt-32", img: FrameImage79 },
  { id: "t-shirt-32", img: FrameImage81 },
  { id: "t-shirt-32", img: FrameImage82 },
  { id: "t-shirt-32", img: FrameImage83 },
  { id: "t-shirt-32", img: FrameImage84 },
  { id: "t-shirt-32", img: FrameImage85 },
  { id: "t-shirt-32", img: FrameImage86 },
  { id: "t-shirt-32", img: FrameImage87 },
  { id: "t-shirt-32", img: FrameImage88 },
  { id: "t-shirt-32", img: FrameImage89 },
  { id: "t-shirt-32", img: FrameImage90 },
  { id: "t-shirt-32", img: FrameImage91 },
  { id: "t-shirt-32", img: FrameImage92 },
  { id: "t-shirt-32", img: FrameImage93 },
  { id: "t-shirt-32", img: FrameImage94 },
  { id: "t-shirt-32", img: FrameImage95 },
  { id: "t-shirt-32", img: FrameImage96 },
  { id: "t-shirt-32", img: FrameImage97 },
  { id: "t-shirt-32", img: FrameImage98 },
  { id: "t-shirt-32", img: FrameImage99 },
  { id: "t-shirt-32", img: FrameImage100 },
  { id: "t-shirt-32", img: FrameImage101 },
  { id: "t-shirt-32", img: FrameImage18 },
  { id: "t-shirt-32", img: FrameImage29 },
];

//animComp==>anim1.js==>images
export const images1 = [
  {
    id: "t-shirt-32",
    img: FrameImage6,
    style: { "--r": 1, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage5,
    style: { "--r": 2, "--c": 5, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage7,
    style: { "--r": 3, "--c": 3, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage9,
    style: { "--r": 4, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage10,
    style: { "--r": 5, "--c": 3, "--s": 5 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage14,
    style: { "--r": 6, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage16,
    style: { "--r": 7, "--c": 3, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage17,
    style: { "--r": 8, "--c": 6, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage20,
    style: { "--r": 9, "--c": 1, "--s": 5 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage21,
    style: { "--r": 10, "--c": 6, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage22,
    style: { "--r": 11, "--c": 4, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage23,
    style: { "--r": 12, "--c": 1, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage24,
    style: { "--r": 13, "--c": 4, "--s": 5 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage26,
    style: { "--r": 14, "--c": 1, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage27,
    style: { "--r": 15, "--c": 4, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage28,
    style: { "--r": 16, "--c": 1, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage33,
    style: { "--r": 17, "--c": 4, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage34,
    style: { "--r": 18, "--c": 1, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage35,
    style: { "--r": 19, "--c": 4, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage36,
    style: { "--r": 20, "--c": 1, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage37,
    style: { "--r": 21, "--c": 4, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage63,
    style: { "--r": 22, "--c": 6, "--s": 5 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage65,
    style: { "--r": 23, "--c": 4, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage66,
    style: { "--r": 24, "--c": 2, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage67,
    style: { "--r": 25, "--c": 4, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage68,
    style: { "--r": 26, "--c": 7, "--s": 5 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage69,
    style: { "--r": 27, "--c": 5, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage70,
    style: { "--r": 28, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage71,
    style: { "--r": 29, "--c": 5, "--s": 5 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage72,
    style: { "--r": 30, "--c": 2, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage73,
    style: { "--r": 31, "--c": 5, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage74,
    style: { "--r": 32, "--c": 3, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage75,
    style: { "--r": 33, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage76,
    style: { "--r": 34, "--c": 3, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage78,
    style: { "--r": 35, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage77,
    style: { "--r": 36, "--c": 3, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage79,
    style: { "--r": 37, "--c": 6, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage81,
    style: { "--r": 38, "--c": 1, "--s": 5 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage82,
    style: { "--r": 39, "--c": 6, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage83,
    style: { "--r": 40, "--c": 4, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage84,
    style: { "--r": 41, "--c": 1, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage85,
    style: { "--r": 42, "--c": 4, "--s": 5 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage87,
    style: { "--r": 43, "--c": 1, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage88,
    style: { "--r": 44, "--c": 4, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage86,
    style: { "--r": 45, "--c": 6, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage89,
    style: { "--r": 46, "--c": 3, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage90,
    style: { "--r": 47, "--c": 6, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage92,
    style: { "--r": 48, "--c": 3, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage91,
    style: { "--r": 49, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage93,
    style: { "--r": 50, "--c": 3, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage94,
    style: { "--r": 51, "--c": 5, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage95,
    style: { "--r": 52, "--c": 7, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage96,
    style: { "--r": 53, "--c": 5, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage97,
    style: { "--r": 54, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage98,
    style: { "--r": 55, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage101,
    style: { "--r": 56, "--c": 3, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage29,
    style: { "--r": 57, "--c": 5, "--s": 5 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage18,
    style: { "--r": 58, "--c": 4, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage99,
    style: { "--r": 59, "--c": 1, "--s": 4 },
  },
  {
    id: "t-705-shirt-brave",
    img: FrameImage100,
    style: { "--r": 60, "--c": 5, "--s": 5 },
  },

  // { id: "t-shirt-32", img: FrameImage18,style: { "--r": 60, "--c": 6, "--s": 3 }, },
];

//animComp==>anim2.js==>images

export const images2 = [
  {
    id: "t-shirt-32",
    img: FrameImage6,

    style: { "--r": 1, "--c": 4, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage5,
    style: { "--r": 2, "--c": 1, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage7,
    style: { "--r": 3, "--c": 4, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage9,
    style: { "--r": 4, "--c": 2, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage10,
    style: { "--r": 5, "--c": 4, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage14,
    style: { "--r": 6, "--c": 2, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage16,
    style: { "--r": 7, "--c": 4, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage17,
    style: { "--r": 8, "--c": 2, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage20,
    style: { "--r": 9, "--c": 4, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage21,
    style: { "--r": 10, "--c": 7, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage22,
    style: { "--r": 11, "--c": 3, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage23,
    style: { "--r": 12, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage24,
    style: { "--r": 13, "--c": 3, "--s": 5 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage26,
    style: { "--r": 14, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage27,
    style: { "--r": 15, "--c": 3, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage28,
    style: { "--r": 16, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage33,
    style: { "--r": 17, "--c": 3, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage34,
    style: { "--r": 18, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage35,
    style: { "--r": 19, "--c": 3, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage36,
    style: { "--r": 20, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage37,
    style: { "--r": 21, "--c": 3, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage63,
    style: { "--r": 22, "--c": 7, "--s": 5 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage65,
    style: { "--r": 23, "--c": 3, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage66,
    style: { "--r": 24, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage67,
    style: { "--r": 25, "--c": 3, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage68,
    style: { "--r": 26, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage69,
    style: { "--r": 27, "--c": 3, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage70,
    style: { "--r": 28, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage71,
    style: { "--r": 29, "--c": 3, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage72,
    style: { "--r": 30, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage73,
    style: { "--r": 31, "--c": 3, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage74,
    style: { "--r": 32, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage75,
    style: { "--r": 33, "--c": 3, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage76,
    style: { "--r": 34, "--c": 7, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage77,
    style: { "--r": 35, "--c": 3, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage78,
    style: { "--r": 36, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage79,
    style: { "--r": 37, "--c": 3, "--s": 5 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage81,
    style: { "--r": 38, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage82,
    style: { "--r": 39, "--c": 3, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage83,
    style: { "--r": 40, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage84,
    style: { "--r": 41, "--c": 3, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage85,
    style: { "--r": 42, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage86,
    style: { "--r": 43, "--c": 3, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage87,
    style: { "--r": 44, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage88,
    style: { "--r": 45, "--c": 3, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage89,
    style: { "--r": 46, "--c": 7, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage90,
    style: { "--r": 47, "--c": 3, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage91,
    style: { "--r": 48, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage92,
    style: { "--r": 49, "--c": 3, "--s": 5 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage93,
    style: { "--r": 50, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage94,
    style: { "--r": 51, "--c": 3, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage95,
    style: { "--r": 52, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage96,
    style: { "--r": 53, "--c": 3, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage97,
    style: { "--r": 54, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage98,
    style: { "--r": 55, "--c": 3, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage99,
    style: { "--r": 56, "--c": 5, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage100,
    style: { "--r": 57, "--c": 7, "--s": 3 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage101,
    style: { "--r": 58, "--c": 3, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage18,
    style: { "--r": 59, "--c": 1, "--s": 2 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage29,
    style: { "--r": 60, "--c": 3, "--s": 5 },
  },
];

//animComp==>anim3.js==>images

export const images3 = [
  {
    id: "t-shirt-32",
    img: FrameImage6,
    style: { "--r": 1, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage5,
    style: { "--r": 2, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage7,
    style: { "--r": 3, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage9,
    style: { "--r": 4, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage10,
    style: { "--r": 5, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage14,
    style: { "--r": 6, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage16,
    style: { "--r": 7, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage17,
    style: { "--r": 8, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage20,
    style: { "--r": 9, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage21,
    style: { "--r": 10, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage22,
    style: { "--r": 11, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage23,
    style: { "--r": 12, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage24,
    style: { "--r": 13, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage26,
    style: { "--r": 14, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage27,
    style: { "--r": 15, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage28,
    style: { "--r": 16, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage33,
    style: { "--r": 17, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage34,
    style: { "--r": 18, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage35,
    style: { "--r": 19, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage36,
    style: { "--r": 20, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage37,
    style: { "--r": 21, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage63,
    style: { "--r": 22, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage65,
    style: { "--r": 23, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage66,
    style: { "--r": 24, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage67,
    style: { "--r": 25, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage68,
    style: { "--r": 26, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage69,
    style: { "--r": 27, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage70,
    style: { "--r": 28, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage71,
    style: { "--r": 29, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage72,
    style: { "--r": 30, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage73,
    style: { "--r": 31, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage74,
    style: { "--r": 32, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage75,
    style: { "--r": 33, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage76,
    style: { "--r": 34, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage77,
    style: { "--r": 35, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage78,
    style: { "--r": 36, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage79,
    style: { "--r": 37, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage81,
    style: { "--r": 38, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage82,
    style: { "--r": 39, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage83,
    style: { "--r": 40, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage84,
    style: { "--r": 41, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage85,
    style: { "--r": 42, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage86,
    style: { "--r": 43, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage87,
    style: { "--r": 44, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage88,
    style: { "--r": 45, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage89,
    style: { "--r": 46, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage90,
    style: { "--r": 47, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage91,
    style: { "--r": 48, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage92,
    style: { "--r": 49, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage93,
    style: { "--r": 50, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage94,
    style: { "--r": 51, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage95,
    style: { "--r": 52, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage96,
    style: { "--r": 53, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage97,
    style: { "--r": 54, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage98,
    style: { "--r": 55, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage99,
    style: { "--r": 56, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage100,
    style: { "--r": 57, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage101,
    style: { "--r": 58, "--c": 1, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage18,
    style: { "--r": 59, "--c": 5, "--s": 4 },
  },
  {
    id: "t-shirt-32",
    img: FrameImage29,
    style: { "--r": 60, "--c": 1, "--s": 4 },
  },
];

//animComp==>anim3.js==>images
export const images4 = [
  { id: "t-shirt-32", img: FrameImage6,style: { "--r": 1, "--c": 3, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage5,style: { "--r": 2, "--c": 5, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage7,style: { "--r": 3, "--c": 7, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage9, style: { "--r": 4, "--c": 5, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage10,  style: { "--r": 5, "--c": 3, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage14, style: { "--r": 6, "--c": 1, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage16, style: { "--r": 7, "--c": 3, "--s": 2 },},
  { id: "t-shirt-32", img: FrameImage17, style: { "--r": 8, "--c": 5, "--s": 2 },},
  { id: "t-shirt-32", img: FrameImage20,style: { "--r": 9, "--c": 7, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage21,style: { "--r": 10, "--c": 5, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage22,style: { "--r": 11, "--c": 3, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage23, style: { "--r": 12, "--c": 1, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage24, style: { "--r": 13, "--c": 3, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage26,style: { "--r": 14, "--c": 5, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage27,   style: { "--r": 15, "--c": 7, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage28, style: { "--r": 16, "--c": 5, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage33, style: { "--r": 17, "--c": 7, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage34, style: { "--r": 18, "--c": 5, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage35, style: { "--r": 19, "--c": 3, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage36,style: { "--r": 20, "--c": 1, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage37, style: { "--r": 21, "--c": 3, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage63, style: { "--r": 22, "--c": 5, "--s": 2 },},
  { id: "t-shirt-32", img: FrameImage65,style: { "--r": 23, "--c": 7, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage66,style: { "--r": 24, "--c": 5, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage67,style: { "--r": 25, "--c": 3, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage68,style: { "--r": 26, "--c": 1, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage69,style: { "--r": 27, "--c": 3, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage70,style: { "--r": 28, "--c": 5, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage71, style: { "--r": 29, "--c": 7, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage72,style: { "--r": 30, "--c": 5, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage73, style: { "--r": 31, "--c": 7, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage74, style: { "--r": 32, "--c": 5, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage75, style: { "--r": 33, "--c": 3, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage76, style: { "--r": 34, "--c": 1, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage77, style: { "--r": 35, "--c": 3, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage78,style: { "--r": 36, "--c": 5, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage79,  style: { "--r": 37, "--c": 7, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage81,style: { "--r": 38, "--c": 5, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage82, style: { "--r": 39, "--c": 3, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage83,style: { "--r": 40, "--c": 1, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage84,style: { "--r": 41, "--c": 3, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage85, style: { "--r": 42, "--c": 5, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage86,style: { "--r": 43, "--c": 7, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage87,style: { "--r": 44, "--c": 5, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage88, style: { "--r": 45, "--c": 7, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage89,style: { "--r": 46, "--c": 5, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage90,style: { "--r": 47, "--c": 3, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage91,style: { "--r": 48, "--c": 1, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage92,style: { "--r": 49, "--c": 3, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage93,style: { "--r": 50, "--c": 5, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage94, style: { "--r": 51, "--c": 7, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage95,  style: { "--r": 52, "--c": 5, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage96,style: { "--r": 53, "--c": 3, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage97, style: { "--r": 54, "--c": 1, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage98, style: { "--r": 55, "--c": 3, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage99, style: { "--r": 56, "--c": 5, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage100, style: { "--r": 57, "--c": 7, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage101, style: { "--r": 58, "--c": 5, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage18,style: { "--r": 59, "--c": 7, "--s": 2 }, },
  { id: "t-shirt-32", img: FrameImage29, style: { "--r": 60, "--c": 5, "--s": 2 }, },
];
