


import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link, NavLink } from 'react-router-dom';
// import './productHoverAnim/hoverCss/base.css'
// import './productHoverAnim/hoverCss/splitting-cells.css'
// import './productHoverAnim/hoverCss/splitting.css'


const ProductCard = ({ product,valueTobe}) => {
  

return(
  
  <div>
   <NavLink to={`/product/0/${product.slug}`} className="flex mx-auto sm:mx-auto">
    <motion.article
      className="group  flex flex-col  bg-white 
      shadow-sm rounded-lg overflow-hidden hover:shadow-xl 
      hover:shadow-gray-100 
      transition-all w-full   h-full 
      lg:h-[450px] lg:w[319px]"
      whileHover={{ scale: 1.05 }}
    >
    
      <div className='py-6'>
      <div className="w-full   sm:h-64 overflow-hidden card">
        <motion.img
          src={product.cover.Tshirt[0]}
          alt={product.name}
          className="object-cover mt-0 object-center w-full h-full  grayscale-[0.1] group-hover:grayscale-0 group-hover:scale-105 transition-all rounded-t-lg"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          // whileHover={{ scale: 1.8 }}
          whileTap={{ scale: 2 }}
        />
          {/* <div
                style={{
                  backgroundImage: `url(${product.cover?.Tshirt?.[0] || ''})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  width: '100%',
                  height: '100%',
                }}
                className="inset-0 card__img"
              ></div> */}
      </div>
      </div>

     
      <div className="p-4 md:p-6 flex-1 flex flex-col justify-between">
        <div>
          <motion.h3
            className="font-bold font-satoshi text-[#383838] text-base md:text-lg truncate card__title"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            data-splitting
          >
            {product.name}
          </motion.h3>
          <motion.p
            className="font-regular font-satoshi text-[#383838] md:text-base truncate mt-2 card__link"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            data-splitting
          >
            {product.description}
          </motion.p>
        </div>
       
        <motion.div
          className="text-right mt-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          data-splitting
        >
          <span className="font-bold font-satoshi text-[#383838] text-lg md:text-xl card__date">₹{product.price[0]}</span>
        </motion.div>
      </div>
    </motion.article>
  </NavLink>
 </div>
)}

export default ProductCard;
