import React, { useRef, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Layout from "../layouts/layout";
import { products } from "../data/productData";
import ProductCard from "../components/productCard";
import { useParams, useNavigate, useLocation, NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { LuArrowRightCircle, LuArrowLeftCircle } from "react-icons/lu";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ArrowRight from "../assets/images/Vector.png";
import "./productPage.css";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import { usePostHog } from "posthog-js/react";
import Image39 from "../assets/printsAssets/image 39.png";
import Image40 from "../assets/printsAssets/image 40.png";
import SizeChartModal from "./modal";
import { MdShoppingCartCheckout } from "react-icons/md";
import { BsFillCartFill } from "react-icons/bs";
import { BsCart4 } from "react-icons/bs";
import { toast } from "react-toastify";
import FullSleveCard from "../components/fullSleveProdctCard";
import TshirtProductCard from "../components/tshirtProductCard";
import HoodieProductCard from "../components/hoodieProductData";
import SingleCheckoutModal from "./cartPage/singleProductBuyModal";

const ProductPage = () => {
  const { value, param } = useParams();

  const navigate = useNavigate();
  const posthog = usePostHog();
  const initialState = parseInt(value) || 0;
  const [shirtState, setShirtState] = useState(initialState);

  const [isInCart, setIsInCart] = useState(false);
  const [sizeState, setSizeState] = useState(null);
  const [sizeMessage, setSizeMessage] = useState(null);
  console.log(sizeState);
  const location = useLocation();
  // const product = products.find((product) => product.slug === slug || product.print=== print);

  const product = products.find(
    (product) => product.slug === param || product.print === param
  );

  console.log(product);
  const productSectionRef = useRef(null);
  const [animationDirection, setAnimationDirection] = useState({
    x: 450,
    y: 800,
  });
  const [shirtPrice, setShirtPrice] = useState(product?.price[initialState]);

  const [selectedProductRect, setSelectedProductRect] = useState(null);
  const [swiperNav, setSwiperNav] = useState(false);
  const [storedBGColor, setBgColor] = useState(null);
  const [isOpen, setIsOpen] = useState(false);








  useEffect(() => {
    if (productSectionRef.current) {
      productSectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [product]);

  useEffect(() => {
    const bgColors = localStorage.getItem("bgcolor");
    console.log(bgColors);
    setBgColor(bgColors);
    //  posthog.capture("$feature_flag_called", {
    //   $feature_flag: "color-combo",
    //   "$feature/color-combo": bgColors,
    //   $feature_flag_response: bgColors,
    //   $override_feature_flags: bgColors,
    // });
  }, [storedBGColor]);

  useEffect(() => {
    document.addEventListener("click", (event) => {
      if (location.pathname === "/") return;
      if (event?.target?.tagName === "A") {
        document.querySelector(".animate-in")?.classList.add("opacity-0");
      }
    });
  }, [location.pathname]);

  // useEffect(() => {
  //   const cart = JSON.parse(localStorage.getItem("cart")) || [];
  //   const productInCart = cart.some((item) => item.id === product.id);
  //   setIsInCart(productInCart);
  // }, [product.id]);

  //   useEffect(() => {
  //     const cart = JSON.parse(localStorage.getItem("cart")) || [];

  //     const coverTypeMappings = {
  //         0: "Tshirt",
  //         1: "FullSleve",
  //         2: "Hoodie"
  //     };

  //     const selectedCoverType = coverTypeMappings[shirtState];

  //     const productInCart = cart.some((item) => {
  //         if (item.id === product.id) {

  //             if (item.coverType === selectedCoverType && item.size === sizeState) {
  //                 return true;
  //             } else {
  //                 return false;
  //             }
  //         }
  //         return false;
  //     });

  //     setIsInCart(productInCart);
  // }, [product.id, shirtState,sizeState]);

  const prevSizeState = useRef(sizeState);

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];

    const coverTypeMappings = {
      0: "Tshirt",
      1: "FullSleve",
      2: "Hoodie",
    };

    const selectedCoverType = coverTypeMappings[shirtState];

    const productInCart = cart.some((item) => {
      return (
        item?.id === product?.id &&
        item.coverType === selectedCoverType &&
        item.size === sizeState
      );
    });

    if (productInCart && prevSizeState.current !== sizeState) {
      toast.info(
        "Item with selected Size already in cart. You can incerease quantity in cart page. ",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //  theme:"dark",
        }
      );
    }

    prevSizeState.current = sizeState;

    setIsInCart(productInCart);
  }, [product?.id, shirtState, sizeState]);

  //controls Component

  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();

    return (
      <div className="tools">
        <button onClick={() => zoomIn()}>+</button>
        <button onClick={() => zoomOut()}>-</button>
        <button onClick={() => resetTransform()}>x</button>
      </div>
    );
  };

  if (!product) return <div>Product not found !</div>;

  const spring = {
    type: "tween",
    stiffness: 500,
    damping: 30,
    duration: 0.2,
  };

  const handleProductClick = (newSlug, event) => {
    const rect = event.target.getBoundingClientRect();
    setSelectedProductRect(rect);
    setAnimationDirection({
      x: rect.left - window.innerWidth / 2,
      y: rect.top,
    });
    navigate(`/products/${newSlug}`);
  };

  const handleAddToCart = () => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    console.log(cart);
    const coverTypeMappings = {
      0: "Tshirt",
      1: "FullSleve",
      2: "Hoodie",
    };

    const selectedCoverType = coverTypeMappings[shirtState];
    const selectedCoverImages = product.cover[selectedCoverType];

    console.log("slected images", selectedCoverImages);

    if (sizeState === null) {
      console.log(" please select a size");

      setSizeMessage("Please select a size.");
      setTimeout(() => {
        setSizeMessage("");
      }, 2000);
      return;
    }

    const productInCart = cart.find(
      (item) =>
        item.id === product.id &&
        item.coverType === selectedCoverType &&
        item.size === sizeState
    );

    if (!productInCart) {
      cart.push({
        id: product.id,
        coverType: selectedCoverType,
        name: product.name,
        size: sizeState,
        price: shirtPrice,
        cover: selectedCoverImages,
        quantity: 1,
        slug: product.slug,
      });

      toast.success("Item added to cart successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        //  theme:"dark"
      });
    }

    localStorage.setItem("cart", JSON.stringify(cart));
    setIsInCart(true);
  };





  const openModal = () => {
    if(sizeState ===null) {
      setSizeMessage("Please select a size.");
      setTimeout(() => {
        setSizeMessage("");
      }, 2000);
      return;
    }
    setIsOpen(true);
  };



  const closeModal = () => {
    setIsOpen(false);
    
  };


  const handleRemoveFromCart = () => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];

    const coverTypeMappings = {
      0: "Tshirt",
      1: "FullSleve",
      2: "Hoodie",
    };

    const selectedCoverType = coverTypeMappings[shirtState]; // Get the current cover type based on shirtState

    const updatedCart = cart.filter(
      (item) =>
        !(item.id === product.id && item.coverType === selectedCoverType)
    );

    localStorage.setItem("cart", JSON.stringify(updatedCart));

    const productInCart = updatedCart.some(
      (item) => item.id === product.id && item.coverType === selectedCoverType
    );
    setIsInCart(productInCart);
  };

  // const handleAddToCart = () => {
  //   const cart = JSON.parse(localStorage.getItem("cart")) || [];
  //   console.log(cart);

  //   const productInCart = cart.find((item) => item.id === product.id);

  //   if (!productInCart) {
  //     cart.push({
  //       id: product.id,
  //       name: product.name,
  //       size:sizeState,
  //       price: shirtPrice,
  //       cover: product.cover,
  //       quantity: 1,
  //     });
  //   }

  //   localStorage.setItem("cart", JSON.stringify(cart));
  //   setIsInCart(true);
  // };

  // else {
  //   productInCart.quantity += 1;
  // }

  // const handleRemoveFromCart = () => {
  //   const cart = JSON.parse(localStorage.getItem("cart")) || [];
  //   const updatedCart = cart.filter((item) => item.id !== product.id);

  //   localStorage.setItem("cart", JSON.stringify(updatedCart));
  //   setIsInCart(false);
  // };

  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  // Filter products and chunk them into arrays of four
  const relatedProducts = products.filter(
    (p) => p.category === product.category && p.id !== product.id
  );
  const productChunks = chunkArray(relatedProducts, 4);
  const handletShirt = () => {
    setShirtState(0);
    setShirtPrice(product.price[0]);
  };
  const handletFullSleve = () => {
    setShirtState(1);
    setShirtPrice(product.price[1]);
  };
  const handletHoodie = () => {
    setShirtState(2);
    setShirtPrice(product.price[2]);
  };
  console.log(shirtState);

  const handleSizeXS = () => {
    setSizeState("XS");
  };
  const handleSizeS = () => {
    setSizeState("S");
  };
  const handleSizeM = () => {
    setSizeState("M");
  };
  const handleSizeL = () => {
    setSizeState("L");
  };
  const handleSizeXL = () => {
    setSizeState("XL");
  };
  const handleSizeXXL = () => {
    setSizeState("XXL");
  };

  return (
    <Layout
    >

     
      <div className="flex justify-center flex-col">
        <div className=" py-4 " ref={productSectionRef}>
          <div className=" flex flex-wrap lg:flex lg:justify-between ">
            <div className="flex gap-2  pb-3 items-center text-gray-500 ">
              <NavLink
                to="/"
                className={`flex items-center justify-center w-10 h-10 mr-4  z-1 
                ${
                  storedBGColor === "Muted_Beige"
                    ? "bg-[#0F2B36]"
                    : storedBGColor === "control"
                    ? "bg-black text-white "
                    : storedBGColor === "Soft_Gray"
                    ? "bg-[#F9744D] text-white "
                    : storedBGColor === "Light_Gray"
                    ? "bg-[#6B2055] text-white "
                    : storedBGColor === "Light_Gray_1"
                    ? "bg-[#E9185F] text-white "
                    : storedBGColor === "Cream"
                    ? "bg-[#E54C44] text-white "
                    : storedBGColor === "Charcoal_1"
                    ? "bg-[#F2C4CE] text-white "
                    : storedBGColor === "dark_shade_gary"
                    ? "bg-[#F2C4CE] text-white "
                    : storedBGColor === "Charcoals"
                    ? "bg-[#4BE8F4] text-white "
                    : storedBGColor === "Dark_Purple_Gradient"
                    ? "bg-[#4BE8F4] text-white "
                    : storedBGColor === "black_1"
                    ? "bg-[#FFFFFF] text-black "
                    : ""
                } 
                font-semibold 
                rounded-full`}
              >
                <MdKeyboardArrowLeft className="mt-0 text-3xl font-bold" />
              </NavLink>

              <NavLink
                className={`after:content-['/'] after:pl-2 capitalize font-satoshi 
                font-regular text-[20px] text-[#383838]
            ${
              storedBGColor === "Charcoal_1"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "dark_shade_gary"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "Charcoals"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "Dark_Purple_Gradient"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "black_1"
                ? "text-[#D6D6D6] opacity-85"
                : ""
            }

                
               
                `}
                to="/"
              >
                home
              </NavLink>

              {/* <span className={`after:content-['/'] after:pl-2 
            capitalize font-satoshi font-regular text-[20px] text-[#383838]
            ${
              storedBGColor==="Charcoal_1"?'text-[#D6D6D6] opacity-85':
              storedBGColor==="dark_shade_gary"?'text-[#D6D6D6] opacity-85':
              storedBGColor==="Charcoals"?'text-[#D6D6D6] opacity-85':
              storedBGColor==="Dark_Purple_Gradient"?'text-[#D6D6D6] opacity-85':
              storedBGColor==="black_1"?'text-[#D6D6D6] opacity-85':

              
              ''}
         
            
            
            
            
            `}>
              {product.category}
            </span> */}
              <span
                className={`font-satoshi  text-[20px] text-[#070707] font-[500px]
            ${
              storedBGColor === "Charcoal_1"
                ? "text-[#D6D6D6]"
                : storedBGColor === "dark_shade_gary"
                ? "text-[#D6D6D6]"
                : storedBGColor === "Charcoals"
                ? "text-[#D6D6D6]"
                : storedBGColor === "Dark_Purple_Gradient"
                ? "text-[#D6D6D6]"
                : storedBGColor === "black_1"
                ? "text-[#D6D6D6]"
                : ""
            }

              
          
              
              
              `}
              >
                {product.name}
              </span>
            </div>

            <NavLink
              to="/cart"
              className={`flex items-center justify-center px-4 h-8 mt-1 rounded-md  mr-14  z-1 
                 ${
                storedBGColor === "Muted_Beige"
                  ? "bg-[#0F2B36] text-white transition-all ease-linear duration-100 hover:bg-white hover:text-[#0F2B36] hover:border hover:border-[#0F2B36]"
                  : storedBGColor === "control"
                  ? "bg-black text-white transition-all ease-linear duration-100 hover:bg-white hover:text-black hover:border hover:border-black"
                  : storedBGColor === "Soft_Gray"
                  ? "bg-[#F9744D] text-white transition-all ease-linear duration-100 hover:bg-white hover:text-[#F9744D] hover:border hover:border-[#F9744D]"
                  : storedBGColor === "Light_Gray"
                  ? "bg-[#6B2055] text-white transition-all ease-linear duration-100 hover:bg-white hover:text-[#6B2055] hover:border hover:border-[#6B2055]"
                  : storedBGColor === "Light_Gray_1"
                  ? "bg-[#E9185F] text-white transition-all ease-linear duration-100 hover:bg-white hover:text-[#E9185F] hover:border hover:border-[#E9185F]"
                  : storedBGColor === "Cream"
                  ? "bg-[#E54C44] text-white transition-all ease-linear duration-100 hover:bg-white hover:text-[#E54C44] hover:border hover:border-[#E54C44]"
                  : storedBGColor === "Charcoal_1"
                  ? "bg-[#F2C4CE] text-white transition-all ease-linear duration-100 hover:bg-white hover:text-[#F2C4CE] hover:border hover:border-[#f2abbb]"
                  : storedBGColor === "dark_shade_gary"
                  ? "bg-[#F2C4CE] text-white transition-all ease-linear duration-100 hover:bg-white hover:text-[#F2C4CE] hover:border hover:border-[#F2C4CE]"
                  : storedBGColor === "Charcoals"
                  ? "bg-[#4BE8F4] text-white transition-all ease-linear duration-100 hover:bg-white hover:text-[#4BE8F4] hover:border hover:border-[#4BE8F4]"
                  : storedBGColor === "Dark_Purple_Gradient"
                  ? "bg-[#4BE8F4] text-white transition-all ease-linear duration-100 hover:bg-white hover:text-[#4BE7F4] hover:border hover:border-[#4BE7F4]"
                  : storedBGColor === "black_1"
                  ? "bg-slate-200 text-black transition-all ease-linear duration-100 hover:bg-gray-400 hover:text-black hover:border hover:border-black"
                  : ""
              }
                font-semibold 
                `}
            >
              {/* <MdKeyboardArrowLeft className="mt-0 text-3xl font-bold" /> */}
              <BsCart4 className=" text-[20px] mr-1 " /> Cart
            </NavLink>
          </div>
        </div>
        <div>
        <div className=" flex  flex-col gap-8 lg:flex-row lg:h-[592px]">
          <AnimatePresence mode="wait" initial={false}>
            <motion.div
              key={product.slug}
              className="w-full  lg:w-[563px]"
              initial={{
                x: animationDirection.x || 450,
                y: animationDirection.y || 800,
                opacity: 0,
              }}
              animate={{ x: 0, y: 0, opacity: 1 }}
              exit={{
                x: selectedProductRect
                  ? selectedProductRect.left - window.innerWidth / 2
                  : 0,
                y: selectedProductRect ? selectedProductRect.top : 500,
                opacity: 0,
                transition: { duration: 0.2 },
              }}
              transition={spring}
              onMouseEnter={() => setSwiperNav(true)}
              onMouseLeave={() => setSwiperNav(false)}
            >
              <Swiper
                navigation={swiperNav}
                // autoplay={{
                //   delay: 2500,
                //   disableOnInteraction: false,
                // }}
                // loop={true}
                modules={[Navigation, Pagination, Autoplay]}
                pagination={{
                  clickable: true,
                  type: "bullets",
                }}
                slidesPerView={1}
                spaceBetween={10}
                className={`product-swiper h-auto  sm:h-[350px] md:h-[750px] bg-white lg:h-[610px] rounded-[14px] `}
              >
                {shirtState === 0 ? (
                  <div className="">
                    {product.cover.Tshirt.map((imgSrc, index) => (
                      <SwiperSlide key={index}>
                        {/* <TransformWrapper initialScale={1}>
     

{({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <>
          <Controls />
          <TransformComponent>
            <img src={imgSrc} alt="test" />
         
          </TransformComponent>
        </>
      )}
    </TransformWrapper> */}

                        <TransformWrapper initialScale={1}>
                          <TransformComponent>
                            <img src={imgSrc} alt="product" />
                          </TransformComponent>
                        </TransformWrapper>
                      </SwiperSlide>
                    ))}
                  </div>
                ) : shirtState === 1 ? (
                  <>
                    {product.cover.FullSleve.map((imgSrc, index) => (
                      <SwiperSlide key={index}>
                        <TransformWrapper>
                          <TransformComponent>
                            <img
                              src={imgSrc}
                              alt="product"
                              className="object-cover  w-full h-full mx-auto"
                            />
                          </TransformComponent>
                        </TransformWrapper>
                      </SwiperSlide>
                    ))}
                  </>
                ) : shirtState === 2 ? (
                  <>
                    {product.cover.Hoodie.map((imgSrc, index) => (
                      <SwiperSlide key={index}>
                        <TransformWrapper>
                          <TransformComponent>
                            <img
                              src={imgSrc}
                              alt="product"
                              className="object-cover  w-full h-full mx-auto"
                            />
                          </TransformComponent>
                        </TransformWrapper>
                      </SwiperSlide>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </Swiper>
            </motion.div>
          </AnimatePresence>

          <div className="w-full  lg:w-[439px]">
            <article className="flex flex-col  mt-[-40px] lg:mt-[-20px]  ">
              <div>
                <div className="w-full lg:mx-0 lg:px-0 py-4">
                  <motion.p
                    className={`
                        text-[38px] whitespace-nowrap  
                        ${
                          storedBGColor === "Muted_Beige"
                            ? "text-[#091C25]"
                            : storedBGColor === "control"
                            ? "text-[#383838]"
                            : storedBGColor === "Soft_Gray"
                            ? "text-[#D94E29]"
                            : storedBGColor === "Light_Gray"
                            ? "text-[#6B2055]"
                            : storedBGColor === "Light_Gray_1"
                            ? "text-[#E9185F]"
                            : storedBGColor === "Cream"
                            ? "text-[#E54C44]"
                            : storedBGColor === "Charcoal_1"
                            ? "text-[#F58F7C]"
                            : storedBGColor === "dark_shade_gary"
                            ? "text-[#F58F7C]"
                            : storedBGColor === "Charcoals"
                            ? "text-[#FE5335]"
                            : storedBGColor === "Dark_Purple_Gradient"
                            ? "text-[#FFFFFF]"
                            : storedBGColor === "black_1"
                            ? "text-[#EAA36E]"
                            : ""
                        }  
                        lg:text-[38px] 
                        leading-[70px]  
                        
                        font-black 
                        font-satoshi 
                        animate-in 
                        w-[439px]`}
                    initial={{ opacity: 0, y: "0rem" }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={spring}
                    style={{ wordSpacing: "-0.05em" }}
                  >
                    {product.name}
                  </motion.p>
                </div>
                <div className="mt-[-24px] ">
                  <motion.p
                    className={`lg:w-[420px] h-48px leading-[24.3px]
                        text-[18px] font-satoshi font-regular 
                        ${
                          storedBGColor === "Charcoal_1"
                            ? "text-[#D6D6D6]"
                            : storedBGColor === "dark_shade_gary"
                            ? "text-[#D6D6D6]"
                            : storedBGColor === "Charcoals"
                            ? "text-[#D6D6D6]"
                            : storedBGColor === "Dark_Purple_Gradient"
                            ? "text-[#D6D6D6] "
                            : storedBGColor === "black_1"
                            ? "text-[#D6D6D6]"
                            : ""
                        }
                        
                        
                        
                        
                        `}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                  >
                    {product.description}
                  </motion.p>
                </div>
              </div>
              <div className=" py-6 ">
                <div className="w-full flex justify-between lg:w-[420px]">
                  <p
                    className={`font-satoshi font-bold text-[18px]  
                      leading-[23.46px] text-[#383838]
                      
                      ${
                        storedBGColor === "Charcoal_1"
                          ? "text-[#D6D6D6]"
                          : storedBGColor === "dark_shade_gary"
                          ? "text-[#D6D6D6]"
                          : storedBGColor === "Charcoals"
                          ? "text-[#D6D6D6]"
                          : storedBGColor === "Dark_Purple_Gradient"
                          ? "text-[#D6D6D6] "
                          : storedBGColor === "black_1"
                          ? "text-[#D6D6D6]"
                          : ""
                      }
                        
                      
                      
                      
                      `}
                  >
                    Select Size
                  </p>
                  {/* <span>{sizeMessage}</span> */}
                  <div
                    className={`mr-4
                        
                       
                        
                        
                        `}
                  >
                    {/* <a
                          href="/"
                          className={`cursor-pointer flex -2 font-satoshi font-regular 
                            text-[18px] leading-[24.3px] text-[#383838] 
                             relative right-2
                            
                            
                             
                             
                             
                             `}
                        >
                         
                          Size chart
                         
                    
                          <span className={`text-[18px] font-satoshi font-regular 
                          text-[#383838] py-[4px] mx-[-4px]  
                          leading-[23.46px] absolute left-[84px] cursor-pointer
                        
                          
                          
                          
                          `}>
                            <SlArrowRight />
                          </span>
                        </a> */}
                    <SizeChartModal storedBGColor={storedBGColor} />
                  </div>
                </div>

                <div>
                  <div>
                    <div className=" w-full grid grid-cols-4 gap-2 md:flex md:gap-6 cursor-pointer lg:flex  lg:gap-3 lg:w-[430px]  mt-3 font-satoshi font-regular">
                      <div className="relative group">
                        <div
                          data-tooltip-target="tooltip-default"
                          className={`w-[60px] relative
      h-[60px] border  font-satoshi font-regular rounded-full text-center flex justify-center items-center text-[16px] box-border hover:scale-105 duration-300 transform transition-transform
      
      ${
        storedBGColor === "control"
          ? "border-[#0000004D] hover:border-[#23A7F1]"
          : storedBGColor === "Muted_Beige"
          ? "border-[#0000004D] hover:border-[#134D57]"
          : storedBGColor === "Soft_Gray"
          ? "border-[#0000004D] hover:border-[#F9744D]"
          : storedBGColor === "Light_Gray"
          ? "border-[#0000004D] hover:border-[#6B2055]"
          : storedBGColor === "Light_Gray_1"
          ? "border-[#0000004D] hover:border-[#E9185F]"
          : storedBGColor === "Cream"
          ? "border-[#0000004D] hover:border-[#E54C44]"
          : storedBGColor === "Charcoal_1"
          ? "border-gray-300 text-[#D6D6D6] opacity-40 hover:border-[#FFFFFF]"
          : storedBGColor === "dark_shade_gary"
          ? "border-gray-300 text-[#D6D6D6] opacity-40 hover:border-[#FFFFFF]"
          : storedBGColor === "Charcoals"
          ? "border-gray-300 text-[#D6D6D6] opacity-40 hover:border-[#FFFFFF]"
          : storedBGColor === "black_1"
          ? "border-gray-600 text-[#D6D6D6] hover:border-[#FFFFFF]"
          : storedBGColor === "Dark_Purple_Gradient"
          ? "border-gray-600 text-[#D6D6D6] hover:border-gray-600"
          : ""
      } 

      ${
        sizeState === "XS"
          ? storedBGColor === "control"
            ? "border-[2px] border-[#23A7F1] text-[#23A7F1]"
            : storedBGColor === "Muted_Beige"
            ? "border-[2px] border-[#134D57] text-[#134D57]"
            : storedBGColor === "Soft_Gray"
            ? "border-[2px] border-[#F9744D] text-[#F9744D]"
            : storedBGColor === "Light_Gray"
            ? "border-[2px] border-[#6B2055] text-[#6B2055]"
            : storedBGColor === "Light_Gray_1"
            ? "border-[2px] border-[#E9185F] text-[#E9185F]"
            : storedBGColor === "Cream"
            ? "border-[2px] border-[#E54C44] text-[#E54C44]"
            : storedBGColor === "Charcoal_1"
            ? "border-[2px] border-[#FFFFFF] text-[#D6D6D6] opacity-90"
            : storedBGColor === "dark_shade_gary"
            ? "border-[2px] border-[#FFFFFF] text-[#D6D6D6] opacity-90"
            : storedBGColor === "Charcoals"
            ? "border-[2px] border-[#FFFFFF] text-[#D6D6D6] opacity-90"
            : storedBGColor === "black_1"
            ? "border-[3px] border-[#FFFFFF] text-[#D6D6D6]"
            : storedBGColor === "Dark_Purple_Gradient"
            ? "border-[3px] border-[#FFFFFF] text-[#D6D6D6]"
            : ""
          : ""
      }  
    `}
                          onClick={handleSizeXS}
                        >
                          XS
                        </div>
                        <div
                          id="tooltip-default"
                          role="tooltip"
                          className="absolute w-[270px] h-[85px] bottom-full left-1 transform -translate-x-1 -translate-y-2 z-10 invisible group-hover:visible group-hover:opacity-100 px-4 py-2 text-sm font-medium text-[#383838] bg-white rounded-lg shadow-sm opacity-0 transition-opacity duration-300"
                        >
                          <p className="pb-1 font-satoshi font-regular text-[16px] leading-[21.6px] text-[#383838]">
                            Garment Measurements:{" "}
                            <span className="font-satoshi font-bold text-[#383838] text-[16px] leading-[21.6px]">
                              XS Size
                            </span>
                          </p>
                          <p className="pb-1 font-satoshi font-regular text-[14px] leading-[18.9px] text-[#383838] text-opacity-70">
                            Chest: 41 in
                          </p>
                          <p className="pb-1 font-satoshi font-regular text-[14px] leading-[18.9px] text-[#383838] text-opacity-70">
                            Length: 27 in
                          </p>
                          <div
                            className="tooltip-arrow"
                            data-popper-arrow
                          ></div>
                        </div>
                      </div>

                      <div className="relative group">
                        <div
                          data-tooltip-target="tooltip-default"
                          className={`w-[60px] relative hover:border-[2px]
                            ${
                              storedBGColor === "control"
                                ? "hover:border-[#23A7F1]"
                                : storedBGColor === "Muted_Beige"
                                ? "hover:border-[#134D57]"
                                : storedBGColor === "Soft_Gray"
                                ? "hover:border-[#F9744D]"
                                : storedBGColor === "Light_Gray"
                                ? "hover:border-[#6B2055]"
                                : storedBGColor === "Light_Gray_1"
                                ? "hover:border-[#E9185F]"
                                : storedBGColor === "Cream"
                                ? "hover:border-[#E54C44]"
                                : storedBGColor === "Charcoal_1"
                                ? "border-gray-300 text-[#D6D6D6] opacity-40 hover:border-[#FFFFFF]"
                                : storedBGColor === "dark_shade_gary"
                                ? "border-gray-300 text-[#D6D6D6] opacity-40 hover:border-[#FFFFFF]"
                                : storedBGColor === "Charcoals"
                                ? "border-gray-300 text-[#D6D6D6] opacity-40 hover:border-[#FFFFFF]"
                                : storedBGColor === "black_1"
                                ? "border-gray-600 text-[#D6D6D6] hover:border-[#FFFFFF]"
                                : storedBGColor === "Dark_Purple_Gradient"
                                ? "border-gray-600 text-[#D6D6D6] hover:border-gray-600"
                                : ""
                            } h-[60px] 
                             border border-[#0000004D] font-satoshi font-regular rounded-full text-center  flex justify-center items-center text-[16px] box-border hover:scale-105 duration-300 transform transition-transform
                             
                             ${
                               sizeState === "S"
                                 ? storedBGColor === "control"
                                   ? "border-[2px] border-[#23A7F1] text-[#23A7F1]"
                                   : storedBGColor === "Muted_Beige"
                                   ? "border-[2px] border-[#134D57] text-[#134D57]"
                                   : storedBGColor === "Soft_Gray"
                                   ? "border-[2px] border-[#F9744D] text-[#F9744D]"
                                   : storedBGColor === "Light_Gray"
                                   ? "border-[2px] border-[#6B2055] text-[#6B2055]"
                                   : storedBGColor === "Light_Gray_1"
                                   ? "border-[2px] border-[#E9185F] text-[#E9185F]"
                                   : storedBGColor === "Cream"
                                   ? "border-[2px] border-[#E54C44] text-[#E54C44]"
                                   : storedBGColor === "Charcoal_1"
                                   ? "border-[2px]  border-[#FFFFFF] text-[#D6D6D6] opacity-90"
                                   : storedBGColor === "dark_shade_gary"
                                   ? "border-[2px]  border-[#FFFFFF] text-[#D6D6D6] opacity-90"
                                   : storedBGColor === "Charcoals"
                                   ? "border-[2px]  border-[#FFFFFF] text-[#D6D6D6] opacity-90"
                                   : storedBGColor === "black_1"
                                   ? "border-[3px] border-[#FFFFFF] text-[#D6D6D6]"
                                   : storedBGColor === "Dark_Purple_Gradient"
                                   ? "border-[3px] border-[#FFFFFF] text-[#D6D6D6]"
                                   : ""
                                 : "text-[#383838]"
                             }
                             `}
                          onClick={handleSizeS}
                        >
                          S
                        </div>
                        <div
                          id="tooltip-default"
                          role="tooltip"
                          className="absolute w-[270px] h-[85px] bottom-full left-1 transform -translate-x-1 -translate-y-2 z-10 invisible group-hover:visible group-hover:opacity-100 px-4 py-2 text-sm font-medium text-[#383838] bg-white rounded-lg shadow-sm opacity-0 transition-opacity duration-300"
                        >
                          <p className="pb-1 font-satoshi font-regular text-[16px] leading-[21.6px] text-[#383838]">
                            Garment Measurements :{" "}
                            <span className="font-satoshi font-bold text-[#383838] text-[16px] leading-[21.6px]">
                              S Size
                            </span>
                          </p>

                          <p className="pb-1 font-satoshi font-regular text-[14px] leading-[18.9px] text-[#383838] text-opacity-70">
                            Chest : 41 in
                          </p>
                          <p className="pb-1 font-satoshi font-regular text-[14px] leading-[18.9px] text-[#383838] text-opacity-70">
                            Length : 27 in
                          </p>
                          <div
                            className="tooltip-arrow"
                            data-popper-arrow
                          ></div>
                        </div>
                      </div>

                      <div className="relative group">
                        <div
                          data-tooltip-target="tooltip-default"
                          className={`w-[60px] relative hover:border-[2px]
                            ${
                              storedBGColor === "control"
                                ? "hover:border-[#23A7F1]"
                                : storedBGColor === "Muted_Beige"
                                ? "hover:border-[#134D57]"
                                : storedBGColor === "Soft_Gray"
                                ? "hover:border-[#F9744D]"
                                : storedBGColor === "Light_Gray"
                                ? "hover:border-[#6B2055]"
                                : storedBGColor === "Light_Gray_1"
                                ? "hover:border-[#E9185F]"
                                : storedBGColor === "Cream"
                                ? "hover:border-[#E54C44]"
                                : storedBGColor === "Charcoal_1"
                                ? "border-gray-300 text-[#D6D6D6] opacity-40 hover:border-[#FFFFFF]"
                                : storedBGColor === "dark_shade_gary"
                                ? "border-gray-300 text-[#D6D6D6] opacity-40 hover:border-[#FFFFFF]"
                                : storedBGColor === "Charcoals"
                                ? "border-gray-300 text-[#D6D6D6] opacity-40 hover:border-[#FFFFFF]"
                                : storedBGColor === "black_1"
                                ? "border-gray-600 text-[#D6D6D6] hover:border-[#FFFFFF]"
                                : storedBGColor === "Dark_Purple_Gradient"
                                ? "border-gray-600 text-[#D6D6D6] hover:border-gray-600"
                                : ""
                            } h-[60px] 
                             border border-[#0000004D] font-satoshi font-regular rounded-full text-center  flex justify-center items-center text-[16px] box-border hover:scale-105 duration-300 transform transition-transform
                             
                             ${
                               sizeState === "M"
                                 ? storedBGColor === "control"
                                   ? "border-[2px] border-[#23A7F1] text-[#23A7F1]"
                                   : storedBGColor === "Muted_Beige"
                                   ? "border-[2px] border-[#134D57] text-[#134D57]"
                                   : storedBGColor === "Soft_Gray"
                                   ? "border-[2px] border-[#F9744D] text-[#F9744D]"
                                   : storedBGColor === "Light_Gray"
                                   ? "border-[2px] border-[#6B2055] text-[#6B2055]"
                                   : storedBGColor === "Light_Gray_1"
                                   ? "border-[2px] border-[#E9185F] text-[#E9185F]"
                                   : storedBGColor === "Cream"
                                   ? "border-[2px] border-[#E54C44] text-[#E54C44]"
                                   : storedBGColor === "Charcoal_1"
                                   ? "border-[2px]  border-[#FFFFFF] text-[#D6D6D6] opacity-90"
                                   : storedBGColor === "dark_shade_gary"
                                   ? "border-[2px]  border-[#FFFFFF] text-[#D6D6D6] opacity-90"
                                   : storedBGColor === "Charcoals"
                                   ? "border-[2px]  border-[#FFFFFF] text-[#D6D6D6] opacity-90"
                                   : storedBGColor === "black_1"
                                   ? "border-[3px] border-[#FFFFFF] text-[#D6D6D6]"
                                   : storedBGColor === "Dark_Purple_Gradient"
                                   ? "border-[3px] border-[#FFFFFF] text-[#D6D6D6]"
                                   : ""
                                 : "text-[#383838]"
                             }
                             `}
                          onClick={handleSizeM}
                        >
                          M
                        </div>
                        <div
                          id="tooltip-default"
                          role="tooltip"
                          className="absolute w-[270px] h-[85px] bottom-full left-1 transform -translate-x-1 -translate-y-2 z-10 invisible group-hover:visible group-hover:opacity-100 px-4 py-2 text-sm font-medium text-[#383838] bg-white rounded-lg shadow-sm opacity-0 transition-opacity duration-300"
                        >
                          <p className="pb-1 font-satoshi font-regular text-[16px] leading-[21.6px] text-[#383838]">
                            Garment Measurements :{" "}
                            <span className="font-satoshi font-bold text-[#383838] text-[16px] leading-[21.6px]">
                              M Size
                            </span>
                          </p>

                          <p className="pb-1 font-satoshi font-regular text-[14px] leading-[18.9px] text-[#383838] text-opacity-70">
                            Chest : 41 in
                          </p>
                          <p className="pb-1 font-satoshi font-regular text-[14px] leading-[18.9px] text-[#383838] text-opacity-70">
                            Length : 27 in
                          </p>
                          <div
                            className="tooltip-arrow"
                            data-popper-arrow
                          ></div>
                        </div>
                      </div>

                      <div className="relative group">
                        <div
                          data-tooltip-target="tooltip-default"
                          className={`w-[60px] relative hover:border-[2px]
                            ${
                              storedBGColor === "control"
                                ? "hover:border-[#23A7F1]"
                                : storedBGColor === "Muted_Beige"
                                ? "hover:border-[#134D57]"
                                : storedBGColor === "Soft_Gray"
                                ? "hover:border-[#F9744D]"
                                : storedBGColor === "Light_Gray"
                                ? "hover:border-[#6B2055]"
                                : storedBGColor === "Light_Gray_1"
                                ? "hover:border-[#E9185F]"
                                : storedBGColor === "Cream"
                                ? "hover:border-[#E54C44]"
                                : storedBGColor === "Charcoal_1"
                                ? "border-gray-300 text-[#D6D6D6] opacity-40 hover:border-[#FFFFFF]"
                                : storedBGColor === "dark_shade_gary"
                                ? "border-gray-300 text-[#D6D6D6] opacity-40 hover:border-[#FFFFFF]"
                                : storedBGColor === "Charcoals"
                                ? "border-gray-300 text-[#D6D6D6] opacity-40 hover:border-[#FFFFFF]"
                                : storedBGColor === "black_1"
                                ? "border-gray-600 text-[#D6D6D6] hover:border-[#FFFFFF]"
                                : storedBGColor === "Dark_Purple_Gradient"
                                ? "border-gray-600 text-[#D6D6D6] hover:border-gray-600"
                                : ""
                            } h-[60px] 
                             border border-[#0000004D] font-satoshi font-regular rounded-full text-center  flex justify-center items-center text-[16px] box-border hover:scale-105 duration-300 transform transition-transform
                             
                             ${
                               sizeState === "L"
                                 ? storedBGColor === "control"
                                   ? "border-[2px] border-[#23A7F1] text-[#23A7F1]"
                                   : storedBGColor === "Muted_Beige"
                                   ? "border-[2px] border-[#134D57] text-[#134D57]"
                                   : storedBGColor === "Soft_Gray"
                                   ? "border-[2px] border-[#F9744D] text-[#F9744D]"
                                   : storedBGColor === "Light_Gray"
                                   ? "border-[2px] border-[#6B2055] text-[#6B2055]"
                                   : storedBGColor === "Light_Gray_1"
                                   ? "border-[2px] border-[#E9185F] text-[#E9185F]"
                                   : storedBGColor === "Cream"
                                   ? "border-[2px] border-[#E54C44] text-[#E54C44]"
                                   : storedBGColor === "Charcoal_1"
                                   ? "border-[2px]  border-[#FFFFFF] text-[#D6D6D6] opacity-90"
                                   : storedBGColor === "dark_shade_gary"
                                   ? "border-[2px]  border-[#FFFFFF] text-[#D6D6D6] opacity-90"
                                   : storedBGColor === "Charcoals"
                                   ? "border-[2px]  border-[#FFFFFF] text-[#D6D6D6] opacity-90"
                                   : storedBGColor === "black_1"
                                   ? "border-[3px] border-[#FFFFFF] text-[#D6D6D6]"
                                   : storedBGColor === "Dark_Purple_Gradient"
                                   ? "border-[3px] border-[#FFFFFF] text-[#D6D6D6]"
                                   : ""
                                 : "text-[#383838]"
                             }
                             `}
                          onClick={handleSizeL}
                        >
                          L
                        </div>
                        <div
                          id="tooltip-default"
                          role="tooltip"
                          className="absolute w-[270px] h-[85px] bottom-full left-1 transform -translate-x-1 -translate-y-2 z-10 invisible group-hover:visible group-hover:opacity-100 px-4 py-2 text-sm font-medium text-[#383838] bg-white rounded-lg shadow-sm opacity-0 transition-opacity duration-300"
                        >
                          <p className="pb-1 font-satoshi font-regular text-[16px] leading-[21.6px] text-[#383838]">
                            Garment Measurements :{" "}
                            <span className="font-satoshi font-bold text-[#383838] text-[16px] leading-[21.6px]">
                              L Size
                            </span>
                          </p>

                          <p className="pb-1 font-satoshi font-regular text-[14px] leading-[18.9px] text-[#383838] text-opacity-70">
                            Chest : 41 in
                          </p>
                          <p className="pb-1 font-satoshi font-regular text-[14px] leading-[18.9px] text-[#383838] text-opacity-70">
                            Length : 27 in
                          </p>
                          <div
                            className="tooltip-arrow"
                            data-popper-arrow
                          ></div>
                        </div>
                      </div>

                      <div className="relative group">
                        <div
                          data-tooltip-target="tooltip-default"
                          className={`w-[60px] relative hover:border-[2px]
                            ${
                              storedBGColor === "control"
                                ? "hover:border-[#23A7F1]"
                                : storedBGColor === "Muted_Beige"
                                ? "hover:border-[#134D57]"
                                : storedBGColor === "Soft_Gray"
                                ? "hover:border-[#F9744D]"
                                : storedBGColor === "Light_Gray"
                                ? "hover:border-[#6B2055]"
                                : storedBGColor === "Light_Gray_1"
                                ? "hover:border-[#E9185F]"
                                : storedBGColor === "Cream"
                                ? "hover:border-[#E54C44]"
                                : storedBGColor === "Charcoal_1"
                                ? "border-gray-300 text-[#D6D6D6] opacity-40 hover:border-[#FFFFFF]"
                                : storedBGColor === "dark_shade_gary"
                                ? "border-gray-300 text-[#D6D6D6] opacity-40 hover:border-[#FFFFFF]"
                                : storedBGColor === "Charcoals"
                                ? "border-gray-300 text-[#D6D6D6] opacity-40 hover:border-[#FFFFFF]"
                                : storedBGColor === "black_1"
                                ? "border-gray-600 text-[#D6D6D6] hover:border-[#FFFFFF]"
                                : storedBGColor === "Dark_Purple_Gradient"
                                ? "border-gray-600 text-[#D6D6D6] hover:border-gray-600"
                                : ""
                            } h-[60px] 
                             border border-[#0000004D] font-satoshi font-regular rounded-full text-center  flex justify-center items-center text-[16px] box-border hover:scale-105 duration-300 transform transition-transform
                             
                             ${
                               sizeState === "XL"
                                 ? storedBGColor === "control"
                                   ? "border-[2px] border-[#23A7F1] text-[#23A7F1]"
                                   : storedBGColor === "Muted_Beige"
                                   ? "border-[2px] border-[#134D57] text-[#134D57]"
                                   : storedBGColor === "Soft_Gray"
                                   ? "border-[2px] border-[#F9744D] text-[#F9744D]"
                                   : storedBGColor === "Light_Gray"
                                   ? "border-[2px] border-[#6B2055] text-[#6B2055]"
                                   : storedBGColor === "Light_Gray_1"
                                   ? "border-[2px] border-[#E9185F] text-[#E9185F]"
                                   : storedBGColor === "Cream"
                                   ? "border-[2px] border-[#E54C44] text-[#E54C44]"
                                   : storedBGColor === "Charcoal_1"
                                   ? "border-[2px]  border-[#FFFFFF] text-[#D6D6D6] opacity-90"
                                   : storedBGColor === "dark_shade_gary"
                                   ? "border-[2px]  border-[#FFFFFF] text-[#D6D6D6] opacity-90"
                                   : storedBGColor === "Charcoals"
                                   ? "border-[2px]  border-[#FFFFFF] text-[#D6D6D6] opacity-90"
                                   : storedBGColor === "black_1"
                                   ? "border-[3px] border-[#FFFFFF] text-[#D6D6D6]"
                                   : storedBGColor === "Dark_Purple_Gradient"
                                   ? "border-[3px] border-[#FFFFFF] text-[#D6D6D6]"
                                   : ""
                                 : "text-[#383838]"
                             }
                             `}
                          onClick={handleSizeXL}
                        >
                          XL
                        </div>
                        <div
                          id="tooltip-default"
                          role="tooltip"
                          className="absolute w-[270px] h-[85px] bottom-full left-1 transform -translate-x-1 -translate-y-2 z-10 invisible group-hover:visible group-hover:opacity-100 px-4 py-2 text-sm font-medium text-[#383838] bg-white rounded-lg shadow-sm opacity-0 transition-opacity duration-300"
                        >
                          <p className=" pb-1 font-satoshi font-regular text-[16px] leading-[21.6px] text-[#383838]">
                            Garment Measurements :{" "}
                            <span className="font-satoshi font-bold text-[#383838] text-[16px] leading-[21.6px]">
                              XL Size
                            </span>
                          </p>

                          <p className="pb-1 font-satoshi font-regular text-[14px] leading-[18.9px] text-[#383838] text-opacity-70">
                            Chest : 41 in
                          </p>
                          <p className="pb-1 font-satoshi font-regular text-[14px] leading-[18.9px] text-[#383838] text-opacity-70">
                            Length : 27 in
                          </p>
                          <div
                            className="tooltip-arrow"
                            data-popper-arrow
                          ></div>
                        </div>
                      </div>

                      <div className="relative group">
                        <div
                          data-tooltip-target="tooltip-default"
                          className={`w-[60px] relative hover:border-[2px]
                            ${
                              storedBGColor === "control"
                                ? "hover:border-[#23A7F1]"
                                : storedBGColor === "Muted_Beige"
                                ? "hover:border-[#134D57]"
                                : storedBGColor === "Soft_Gray"
                                ? "hover:border-[#F9744D]"
                                : storedBGColor === "Light_Gray"
                                ? "hover:border-[#6B2055]"
                                : storedBGColor === "Light_Gray_1"
                                ? "hover:border-[#E9185F]"
                                : storedBGColor === "Cream"
                                ? "hover:border-[#E54C44]"
                                : storedBGColor === "Charcoal_1"
                                ? "border-gray-300 text-[#D6D6D6] opacity-40 hover:border-[#FFFFFF]"
                                : storedBGColor === "dark_shade_gary"
                                ? "border-gray-300 text-[#D6D6D6] opacity-40 hover:border-[#FFFFFF]"
                                : storedBGColor === "Charcoals"
                                ? "border-gray-300 text-[#D6D6D6] opacity-40 hover:border-[#FFFFFF]"
                                : storedBGColor === "black_1"
                                ? "border-gray-600 text-[#D6D6D6] hover:border-[#FFFFFF]"
                                : storedBGColor === "Dark_Purple_Gradient"
                                ? "border-gray-600 text-[#D6D6D6] hover:border-gray-600"
                                : ""
                            } h-[60px] 
                             border border-[#0000004D] font-satoshi font-regular rounded-full text-center  flex justify-center items-center text-[16px] box-border hover:scale-105 duration-300 transform transition-transform
                             
                             ${
                               sizeState === "XXL"
                                 ? storedBGColor === "control"
                                   ? "border-[2px] border-[#23A7F1] text-[#23A7F1]"
                                   : storedBGColor === "Muted_Beige"
                                   ? "border-[2px] border-[#134D57] text-[#134D57]"
                                   : storedBGColor === "Soft_Gray"
                                   ? "border-[2px] border-[#F9744D] text-[#F9744D]"
                                   : storedBGColor === "Light_Gray"
                                   ? "border-[2px] border-[#6B2055] text-[#6B2055]"
                                   : storedBGColor === "Light_Gray_1"
                                   ? "border-[2px] border-[#E9185F] text-[#E9185F]"
                                   : storedBGColor === "Cream"
                                   ? "border-[2px] border-[#E54C44] text-[#E54C44]"
                                   : storedBGColor === "Charcoal_1"
                                   ? "border-[2px]  border-[#FFFFFF] text-[#D6D6D6] opacity-90"
                                   : storedBGColor === "dark_shade_gary"
                                   ? "border-[2px]  border-[#FFFFFF] text-[#D6D6D6] opacity-90"
                                   : storedBGColor === "Charcoals"
                                   ? "border-[2px]  border-[#FFFFFF] text-[#D6D6D6] opacity-90"
                                   : storedBGColor === "black_1"
                                   ? "border-[3px] border-[#FFFFFF] text-[#D6D6D6]"
                                   : storedBGColor === "Dark_Purple_Gradient"
                                   ? "border-[3px] border-[#FFFFFF] text-[#D6D6D6]"
                                   : ""
                                 : "text-[#383838]"
                             }
                             `}
                          onClick={handleSizeXXL}
                        >
                          XXL
                        </div>
                        <div
                          id="tooltip-default"
                          role="tooltip"
                          className="absolute w-[280px] h-[85px] bottom-full left-1 transform -translate-x-1 -translate-y-2 z-10 invisible group-hover:visible group-hover:opacity-100 px-4 py-2 text-sm font-medium text-[#383838] bg-white rounded-lg shadow-sm opacity-0 transition-opacity duration-300"
                        >
                          <p className="pb-1 font-satoshi font-regular text-[16px] leading-[21.6px] text-[#383838]">
                            Garment Measurements :{" "}
                            <span className="font-satoshi font-bold text-[#383838] text-[16px] leading-[21.6px]">
                              XXL Size
                            </span>
                          </p>

                          <p className=" pb-1 font-satoshi font-regular text-[14px] leading-[18.9px] text-[#383838] text-opacity-70">
                            Chest : 41 in
                          </p>
                          <p className=" font-satoshi font-regular text-[14px] leading-[18.9px] text-[#383838] text-opacity-70">
                            Length : 27 in
                          </p>
                          <div
                            className="tooltip-arrow"
                            data-popper-arrow
                          ></div>
                        </div>
                      </div>
                    </div>
                    {/* <span>{sizeMessage}</span> */}
                  </div>
                </div>
                <span
                  className={`absolute
              text-red-600
              font-satoshi
              font-bold 
              mt-1
              `}
                >
                  {sizeMessage}
                </span>
              </div>
              <div className=" w-full md:px-0 lg:px-0  flex flex-col lg:w-[434px]">
                <motion.div
                  className={`text-[38px]
                      
                      ${
                        storedBGColor === "Muted_Beige"
                          ? "text-[#091C25]"
                          : storedBGColor === "control"
                          ? "text-[#383838]"
                          : storedBGColor === "Soft_Gray"
                          ? "text-[#D94E29]"
                          : storedBGColor === "Light_Gray"
                          ? "text-[#411332]"
                          : storedBGColor === "Light_Gray_1"
                          ? "text-[#E9185F]"
                          : storedBGColor === "Cream"
                          ? "text-[#E54C44]"
                          : storedBGColor === "Charcoal_1"
                          ? "text-[#F58F7C]"
                          : storedBGColor === "dark_shade_gary"
                          ? "text-[#F58F7C]"
                          : storedBGColor === "Charcoals"
                          ? "text-[#FE5335]"
                          : storedBGColor === "black_1"
                          ? "text-[#EAA36E]"
                          : storedBGColor === "Dark_Purple_Gradient"
                          ? "text-[#D6D6D6]"
                          : ""
                      }  font-satoshi 
                       font-bold 
                       leading-[56.7px]`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  ₹{shirtPrice}
                  {/* { shirtState === 0?
                      product.price[0]
                      .toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                      })
                      :shirtState === 1?  product.price[1]
                      .toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                      })
                      
                      :shirtState === 2? product.price[2]
                      .toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                      })
                      
                      :""
                      } */}
                </motion.div>
                <div
                  className={`text-[16px] font-satoshi font-regular
                     text-[#939393] mt-[-2px]
                      ${
                        storedBGColor === "Charcoal_1"
                          ? "text-[#D6D6D6]"
                          : storedBGColor === "dark_shade_gary"
                          ? "text-[#D6D6D6]"
                          : storedBGColor === "Charcoals"
                          ? "text-[#D6D6D6]"
                          : storedBGColor === "black_1"
                          ? "text-[#D6D6D6]"
                          : storedBGColor === "Dark_Purple_Gradient"
                          ? "text-[#D6D6D6]"
                          : ""
                      }


                  
                    `}
                >
                  * This is a fictional price
                </div>
              </div>
              <div className=" flex flex-col   lg:w-[420px]">
              {/*modal under standing 
              
              1.import modal
              2. declare state for modal visibility and open/close button click handler
              3.Pass the props to thecorresponding modal component.
              
              */}
                <SingleCheckoutModal
                
                sizeState={sizeState} 
                storedBGColor={storedBGColor}
                openModal={openModal}
                closeModal={closeModal}
                isOpen={isOpen}
                />
                <button
                  type="button"
                  disabled={isInCart ? true : false}
                  onClick={handleAddToCart}
                  // onClick={isInCart ? null : handleAddToCart}
                  className={`mt-3 h-[48px]  
                      font-satoshi font-regular text-[18px]  rounded-[4px]  border-[1px] 
                     ${
                       storedBGColor === "Muted_Beige"
                         ? "border-[#0F2B36] text-[#0F2B36] bg-white  "
                         : storedBGColor === "control"
                         ? "border-black text-black  bg-white  "
                         : storedBGColor === "Soft_Gray"
                         ? "border-[#F9744D] text-[#F9744D] bg-white  "
                         : storedBGColor === "Light_Gray"
                         ? "border-[#6B2055] text-[#6B2055] bg-white  "
                         : storedBGColor === "Light_Gray_1"
                         ? "border-[#E9185F] text-[#E9185F] bg-white  "
                         : storedBGColor === "Cream"
                         ? "border-[#E54C44] text-[#E54C44] bg-white  "
                         : storedBGColor === "Charcoal_1"
                         ? "border-[#F2C4CE] border-[2px] bg-white   text-[#F2C4CE]"
                         : storedBGColor === "dark_shade_gary"
                         ? "border-[#F2C4CE] border-[2px] bg-white   text-[#F2C4CE]"
                         : storedBGColor === "Charcoals"
                         ? "border-[#4BE8F4] border-[2px] bg-white   text-[#4BE8F4] opacity-90"
                         : storedBGColor === "Dark_Purple_Gradient"
                         ? " text-[#4BE7F4] border-[#4BE7F4]"
                         : storedBGColor === "black_1"
                         ? "bg-black text-white"
                         : "bg-slate-400"
                     } 
                      sm:full`}
                >
                  {/* {isInCart ? "Product added to cart" : "Add to cart"} */}
                  Add to cart
                </button>
              </div>
              <div>
                <div className=" lg:w-[420px]">
                  <div className="flex gap-4  py-6 cursor-pointer ">
                    <div
                      className={`w-[132px] h-[132px] bg-[#ffffff] 
        rounded-[10.45px]  
        ${
          storedBGColor === "control"
            ? "hover:border-[#23A7F1] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px] "
            : ""
        }
       
        ${
          storedBGColor === "Muted_Beige"
            ? "hover:border-[#134D57] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
            : storedBGColor === "Soft_Gray"
            ? "hover:border-[#F9744D] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
            : storedBGColor === "Light_Gray"
            ? "hover:border-[#6B2055] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
            : storedBGColor === "Light_Gray_1"
            ? "hover:border-[#E9185F] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
            : storedBGColor === "Cream"
            ? "hover:border-[#E54C44] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
            : storedBGColor === "Charcoal_1"
            ? "hover:border-[#F58F7C] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
            : storedBGColor === "dark_shade_gary"
            ? "hover:border-[#F58F7C] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
            : storedBGColor === "Charcoals"
            ? "hover:border-[#4BE8F4] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
            : storedBGColor === "black_1"
            ? "hover:border-[#EAA36E] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
            : storedBGColor === "Dark_Purple_Gradient"
            ? "hover:border-[#D274D2] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
            : ""
        }
       
       
        ${
          shirtState === 0
            ? storedBGColor === "control"
              ? "border-[2px] border-[#23A7F1] text-[#23A7F1]"
              : storedBGColor === "Muted_Beige"
              ? "border-[2px] border-[#134D57] text-[#134D57]"
              : storedBGColor === "Soft_Gray"
              ? "border-[2px] border-[#F9744D] text-[#F9744D]"
              : storedBGColor === "Light_Gray"
              ? "border-[2px] border-[#6B2055] text-[#6B2055]"
              : storedBGColor === "Light_Gray_1"
              ? "border-[2px] border-[#E9185F] text-[#E9185F]"
              : storedBGColor === "Cream"
              ? "border-[2px] border-[#E54C44] text-[#E54C44]"
              : storedBGColor === "Charcoal_1"
              ? "border-[2px] border-[#F58F7C] text-[#F58F7C]"
              : storedBGColor === "dark_shade_gary"
              ? "border-[2px] border-[#F58F7C] text-[#F58F7C]"
              : storedBGColor === "Charcoals"
              ? "border-[2px] border-[#4BE8F4] text-[#4BE8F4]"
              : storedBGColor === "black_1"
              ? "border-[2px] border-[#EAA36E] text-[#EAA36E]"
              : storedBGColor === "Dark_Purple_Gradient"
              ? "border-[2px] border-[#D274D2] text-[#4BE7F4]"
              : ""
            : "text-[#383838]"
        }
      `}
                      onClick={handletShirt}
                    >
                      <img
                        src={product.cover.Tshirt[0]}
                        className="px-4 mt-2"
                        alt="Tshirt"
                      />
                      <p
                        className={`text-center font-satoshi font-[500px] text-sm
        
        
         ${
           shirtState === 0
             ? storedBGColor === "control"
               ? "text-[#23A7F1]"
               : storedBGColor === "Muted_Beige"
               ? " text-[#134D57]"
               : storedBGColor === "Soft_Gray"
               ? " text-[#F9744D]"
               : storedBGColor === "Light_Gray"
               ? "text-[#6B2055]"
               : storedBGColor === "Light_Gray_1"
               ? " text-[#E9185F]"
               : storedBGColor === "Cream"
               ? "text-[#E54C44]"
               : storedBGColor === "Charcoal_1"
               ? "text-[#F58F7C] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
               : storedBGColor === "dark_shade_gary"
               ? "text-[#F58F7C]"
               : storedBGColor === "Charcoals"
               ? "text-[#4BE8F4]"
               : ""
             : "text-[#383838]"
         }
        
        
        
        
        
        `}
                      >
                        T-Shirt
                      </p>
                    </div>

                    <div
                      className={`w-[132px] h-[132px] bg-[#ffffff] hover:border-[2px] 
         rounded-[10.45px]   hover:scale-105 duration-300 transform transition-transform 
        ${
          storedBGColor === "control"
            ? "hover:border-[#23A7F1] text-[#383838]"
            : storedBGColor === "Muted_Beige"
            ? "hover:border-[#134D57] text-[#383838]"
            : storedBGColor === "Soft_Gray"
            ? "hover:border-[#F9744D] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
            : storedBGColor === "Light_Gray"
            ? "hover:border-[#6B2055] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
            : storedBGColor === "Light_Gray_1"
            ? "hover:border-[#E9185F] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
            : storedBGColor === "Cream"
            ? "hover:border-[#E54C44] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
            : storedBGColor === "Charcoal_1"
            ? "hover:border-[#F58F7C] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
            : storedBGColor === "dark_shade_gary"
            ? "hover:border-[#F58F7C] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
            : storedBGColor === "Charcoals"
            ? "hover:border-[#4BE8F4] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
            : storedBGColor === "black_1"
            ? "hover:border-[#EAA36E] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
            : storedBGColor === "Dark_Purple_Gradient"
            ? "hover:border-[#D274D2] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
            : ""
        }
        ${
          shirtState === 1
            ? storedBGColor === "control"
              ? "border-[2px] border-[#23A7F1] text-[#23A7F1]"
              : storedBGColor === "Muted_Beige"
              ? "border-[2px] border-[#134D57] text-[#134D57]"
              : storedBGColor === "Soft_Gray"
              ? "border-[2px] border-[#F9744D] text-[#F9744D]"
              : storedBGColor === "Light_Gray"
              ? "border-[2px] border-[#6B2055] text-[#6B2055]"
              : storedBGColor === "Light_Gray_1"
              ? "border-[2px] border-[#E9185F] text-[#E9185F]"
              : storedBGColor === "Cream"
              ? "border-[2px] border-[#E54C44] text-[#E54C44]"
              : storedBGColor === "Charcoal_1"
              ? "border-[2px] border-[#F58F7C] text-[#F58F7C]"
              : storedBGColor === "dark_shade_gary"
              ? "border-[2px] border-[#F58F7C] text-[#F58F7C]"
              : storedBGColor === "Charcoals"
              ? "border-[2px] border-[#4BE8F4] text-[#4BE8F4]"
              : storedBGColor === "black_1"
              ? "border-[2px] border-[#EAA36E] text-[#EAA36E]"
              : storedBGColor === "Dark_Purple_Gradient"
              ? "border-[2px] border-[#D274D2] text-[#4BE7F4]"
              : ""
            : ""
        }
      `}
                      onClick={handletFullSleve}
                    >
                      <img
                        src={product.cover.FullSleve[0]}
                        className="px-4 mt-2"
                        alt="Fullsleve Shirt"
                      />
                      <p
                        className={`text-center font-satoshi font-[500px] text-sm
        
        
        ${
          shirtState === 1
            ? storedBGColor === "control"
              ? "text-[#23A7F1]"
              : storedBGColor === "Muted_Beige"
              ? " text-[#134D57]"
              : storedBGColor === "Soft_Gray"
              ? " text-[#F9744D]"
              : storedBGColor === "Light_Gray"
              ? "text-[#6B2055]"
              : storedBGColor === "Light_Gray_1"
              ? " text-[#E9185F]"
              : storedBGColor === "Cream"
              ? "text-[#E54C44]"
              : storedBGColor === "Charcoal_1"
              ? "text-[#F58F7C]"
              : storedBGColor === "dark_shade_gary"
              ? "text-[#F58F7C]"
              : storedBGColor === "Charcoals"
              ? "text-[#4BE8F4]"
              : ""
            : "text-[#383838]"
        }
       
       
       
       
       
       `}
                      >
                        Full-Sleeve
                      </p>
                    </div>

                    <div
                      className={`w-[132px] h-[132px] bg-[#ffffff] 
        rounded-[10.45px]  hover:border-[2px] 
            ${
              storedBGColor === "control"
                ? "hover:border-[#23A7F1] text-[#383838]"
                : storedBGColor === "Muted_Beige"
                ? "hover:border-[#134D57] text-[#383838]"
                : storedBGColor === "Soft_Gray"
                ? "hover:border-[#F9744D] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
                : storedBGColor === "Light_Gray"
                ? "hover:border-[#6B2055] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
                : storedBGColor === "Light_Gray_1"
                ? "hover:border-[#E9185F] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
                : storedBGColor === "Cream"
                ? "hover:border-[#E54C44] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
                : storedBGColor === "Charcoal_1"
                ? "hover:border-[#F58F7C] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
                : storedBGColor === "dark_shade_gary"
                ? "hover:border-[#F58F7C] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
                : storedBGColor === "Charcoals"
                ? "hover:border-[#4BE8F4] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
                : storedBGColor === "black_1"
                ? "hover:border-[#EAA36E] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
                : storedBGColor === "Dark_Purple_Gradient"
                ? "hover:border-[#D274D2] text-[#383838] hover:scale-105 duration-300 transform transition-transform hover:border-[2px]"
                : ""
            }
        hover:scale-105 duration-300 transform transition-transform
        ${
          shirtState === 2
            ? storedBGColor === "control"
              ? "border-[2px] border-[#23A7F1] text-[#23A7F1]"
              : storedBGColor === "Muted_Beige"
              ? "border-[2px] border-[#134D57] text-[#134D57]"
              : storedBGColor === "Soft_Gray"
              ? "border-[2px] border-[#F9744D] text-[#F9744D]"
              : storedBGColor === "Light_Gray"
              ? "border-[2px] border-[#6B2055] text-[#6B2055]"
              : storedBGColor === "Light_Gray_1"
              ? "border-[2px] border-[#E9185F] text-[#E9185F]"
              : storedBGColor === "Cream"
              ? "border-[2px] border-[#E54C44] text-[#E54C44]"
              : storedBGColor === "Charcoal_1"
              ? "border-[2px] border-[#F58F7C] text-[#F58F7C]"
              : storedBGColor === "dark_shade_gary"
              ? "border-[2px] border-[#F58F7C] text-[#F58F7C]"
              : storedBGColor === "black_1"
              ? "border-[2px] border-[#EAA36E] text-[#EAA36E]"
              : storedBGColor === "Dark_Purple_Gradient"
              ? "border-[2px] border-[#D274D2] text-[#4BE7F4]"
              : storedBGColor === "Charcoals"
              ? "border-[2px] border-[#EAA36E] text-[#4BE8F4]"
              : ""
            : ""
        }
      `}
                      onClick={handletHoodie}
                    >
                      <img
                        src={product.cover.Hoodie[0]}
                        className="px-4 mt-2"
                        alt="Hoodie"
                      />
                      <p
                        className={`text-center font-satoshi font-[500px] text-sm
        
        
        ${
          shirtState === 2
            ? storedBGColor === "control"
              ? "text-[#23A7F1]"
              : storedBGColor === "Muted_Beige"
              ? " text-[#134D57]"
              : storedBGColor === "Soft_Gray"
              ? " text-[#F9744D]"
              : storedBGColor === "Light_Gray"
              ? "text-[#6B2055]"
              : storedBGColor === "Light_Gray_1"
              ? "text-[#E9185F]"
              : storedBGColor === "Cream"
              ? "text-[#E54C44]"
              : storedBGColor === "Charcoal_1"
              ? "text-[#F58F7C]"
              : storedBGColor === "dark_shade_gary"
              ? "text-[#F58F7C]"
              : storedBGColor === "Charcoals"
              ? "text-[#4BE8F4]"
              : ""
            : "text-[#383838]"
        }
       
       
       
       
       
       `}
                      >
                        Hoodie
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
        </div>
        <div className="flex h-full py-3 lg:py-2 flex-wrap px-2 lg:px-0 justify-around lg:flex lg:justify-between items-center lg:mt-10 rounded-[8px] w-full lg:w-[1015px] bg-[#E1E1E1] lg:h-[80px]">
          <div>
            <div className="flex">
              <img
                className="h-[68px] w-[71.16px] relative left-6 pb-2 mix-blend-exclusion"
                style={{ transform: "rotate(.29deg)" }}
                src={Image40}
                alt="Shirt"
              />

              <img
                className="h-[68px] w-[71.16px] absolute pb-2 "
                style={{ transform: "rotate(-40.29deg)" }}
                src={Image40}
                alt="Shirt"

              />
              <p
                className=" font-satoshi font-bold leading-[22px
        text-[#333333] text-[16px] lg:text-[22px]
        flex justify-center items-center mx-3
      
        "
              >
                <span>Get a discount on a purchase of 10 t-shirts</span>
              </p>
            </div>
          </div>
          <div className="lg:py-0 pt-2">
            <p
              className=" font-satoshi font-bold leading-[30px]
      
        
        text-[#333333] text-[25px] lg:text-[30px]
        
        
        
        "
            >
              40% off
            </p>
          </div>
          <div className="lg:py-0 pt-2 px-4">
            <button
              className=" bg-[#203341] w-[133px] h-[36px] lg:h-[44px]
          font-nunito font-bold text-[#FFFFFF] rounded-[4px]
          
          "
            >
              Buy Now
            </button>
          </div>
        </div>

        <h4
          className={`font-satoshi font-bold text-[#383838] text-[20px] pb-6 py-6
            
              ${
                storedBGColor === "Charcoal_1"
                  ? "text-[#D6D6D6]"
                  : storedBGColor === "dark_shade_gary"
                  ? "text-[#D6D6D6]"
                  : storedBGColor === "Charcoals"
                  ? "text-[#D6D6D6]"
                  : storedBGColor === "Dark_Purple_Gradient"
                  ? "text-[#D6D6D6] "
                  : storedBGColor === "black_1"
                  ? "text-[#D6D6D6]"
                  : ""
              }
            
            
            
            `}
        >
          You might also like
        </h4>

        <div className="relative">
          <Swiper
            navigation={{
              prevEl: ".custom-prev",
              nextEl: ".custom-next",
            }}
            modules={[Navigation, Pagination]}
            spaceBetween={80}
            slidesPerView={1}
            className="related-products-swiper "
          >
            {productChunks.map((chunk, index) => (
              <SwiperSlide key={index}>
                <div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-3 gap-y-5 sm:w-full lg:w-[1020px]  h-full ">
                    {chunk.map((pr) => {
                      return shirtState === 1 ? (
                        <FullSleveCard
                          key={pr.id}
                          product={pr}
                          onClick={(event) =>
                            handleProductClick(pr.slug, event)
                          }
                        />
                      ) : shirtState === 0 ? (
                        <TshirtProductCard
                          key={pr.id}
                          product={pr}
                          onClick={(event) =>
                            handleProductClick(pr.slug, event)
                          }
                        />
                      ) : shirtState === 2 ? (
                        <HoodieProductCard
                          key={pr.id}
                          product={pr}
                          onClick={(event) =>
                            handleProductClick(pr.slug, event)
                          }
                        />
                      ) : (
                        <ProductCard
                          key={pr.id}
                          product={pr}
                          onClick={(event) =>
                            handleProductClick(pr.slug, event)
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className=" hidden lg:block">
          <div className="custom-prev swiper-nav-button">
            <SlArrowLeft size={20} className=" ml-[-3px] " />
          </div>
          <div className="custom-next swiper-nav-button">
            <SlArrowRight size={20} className=" mr-[-7px] " />
          </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductPage;


