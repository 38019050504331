
import React, { useEffect, useState } from 'react';
import Banner from '../assets/printsAssets/Frame 186.png'
import { useLocation } from 'react-router-dom';
import './layout.css'; 

import SideBanner1 from '../banners/sideBanner1';
import SideBanner2 from '../banners/sideBanner2';
const Layout = ({ title, image, description, children }) => {
  const location = useLocation();
  const [bgColor,setBgColor] = useState('')
  const [colorSchemebg,setColorSchemeBg] = useState(null)
  React.useEffect(() => {
    if (location.pathname !== '/') {
      document.querySelectorAll('.animate-in').forEach(element => {
        element.classList.add('opacity-0');
      });
    }
  }, [location.pathname]);


  console.log(bgColor)



  

  useEffect(()=>{

    const storedBgColor = localStorage.getItem('bgcolor');

    if (!storedBgColor) {

      const colorArray = [
        "control",
        "Cream", 
        "Light_Gray",
        "Soft_Gray", 
        "Muted_Beige",
        "Light_Gray_1",
        "Charcoal_1",
        "dark_shade_gary",
        "Charcoals",
        "Dark_Purple_Gradient",
        "black_1"
       ];
      // const colorArray = [
      //   "Dark_Purple_Gradient",
      //   "Charcoal","Cream", 
      //   "Light_Gray",
      //   "Soft_Gray", 
      //   "Muted_Beige",
      //   "Light_Gray_1",
      //   "black_1",
      //   "control",
      //   "Charcoal_1",
      //   "dark_shade_gary"];
      const randomColor = Math.floor(Math.random() * colorArray.length);
    
      setBgColor(colorArray[randomColor]);
      localStorage.setItem('bgcolor', bgColor);
    } else {
      setBgColor(storedBgColor);
    }

  },[bgColor])

  return (
    <>
 <div className={`w-full mx-auto  overflow-x-hidden`}>
 {/* <div
    
 
 >
 afsd
 </div> */}

     
      {/* <div 
   
      className={` flex flex-col lg:flex lg:flex-row `}
      
      >
       <SideBanner1/> */}
  <div 
  className=" max-w-full lg:max-w-6xl mx-auto min-h-screen 
   py-6 sm:py-8 md:py-10 lg:py-1 lg:pb-20 px-4 
    md:px-8 lg:px-10 "
    >
  
  
   <div >
   {children}
   </div>
 

  </div>
 {/* <SideBanner2/>

</div> */}
</div>
</>   
  );
};

export default Layout;



