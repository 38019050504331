import React, { useEffect, useState } from 'react';

import ProductCard from './components/productCard';
import { Route, Router, Routes } from 'react-router-dom';
import HomePage from './pages/home';
import './App.css'
import { products } from './data/productData';
import {images} from './data/animimagesData'
import ProductPage from './pages/productPage';
import CartPage from './pages/cartPage/cart';
// import Example from './pages/animComp1/Demo1'
// import Example from './pages/Demos/Demo5/Demo5'

// import PrintsDemo from './pages/printsDemo'
// import CartListPage from './pages/cartPage/indvidualCartItem'



// import './print.css'







function App() {

  const [bgColor,setBgColor] = useState('')
  useEffect(()=>{

    const storedBgColor = localStorage.getItem('bgcolor');

    if (!storedBgColor) {

      const colorArray = [
        "control",
        "Cream", 
        "Light_Gray",
        "Soft_Gray", 
        "Muted_Beige",
        "Light_Gray_1",
        "Charcoal_1",
        "dark_shade_gary",
        "Charcoals",
        "Dark_Purple_Gradient",
        "black_1"
       ];
      // const colorArray = [
      //   "Dark_Purple_Gradient",
      //   "Charcoal","Cream", 
      //   "Light_Gray",
      //   "Soft_Gray", 
      //   "Muted_Beige",
      //   "Light_Gray_1",
      //   "black_1",
      //   "control",
      //   "Charcoal_1",
      //   "dark_shade_gary"];
      const randomColor = Math.floor(Math.random() * colorArray.length);
    
      setBgColor(colorArray[randomColor]);
      localStorage.setItem('bgcolor', bgColor);
    } else {
      setBgColor(storedBgColor);
    }

  },[bgColor])

  
  return (
    <div className={`${bgColor}`}>
    {/* <h1>kjhvzlk</h1> */}
     <Routes>
     <Route exact path="/" element={
      // <div className=' flex'>
      //   <div className='w-[280px]'>fjhs</div>
      <HomePage/>
      // <div className='w-[280px]'>fjhs</div>

      // </div>
      
      
      } />
        <Route path="/product/:value/:param" element={
          
        //   <div className=' flex '>
        // <div className='w-[280px] border-2 ml-2'>fjhs</div>
        <>
          
          <ProductPage products={products} />
          {/* <svg className="cursor" width={20} height={20} viewBox="0 0 20 20">
        <circle className="cursor__inner" cx={10} cy={10} r={5} />
      </svg> */}

          </>
          
      // <div className='w-[280px] border-2 mr-2'>fjhs</div>

      // </div>
          
          }/>
        {/* <Route path="/product/:value/:slug" element={ <ProductPage products={products} />}/> */}
        
        <Route path='/cart' element={<CartPage/>}/>
      {/* <Route path='/prints' element={<Example images={images.length > 0 ? images : ""}/>}/> */}

        {/* <Route path="/cart/:id/:coverType/:size" element={ <CartListPage />}/> */}
      
     </Routes>
     {/* <h1>kjhvzlk</h1> */}
    </div>
  );
}

export default App;
