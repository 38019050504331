import React, { useEffect, useState } from "react";
import Layout from "../../layouts/layout";
import { NavLink } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { IoAddOutline } from "react-icons/io5";
import { VscAdd } from "react-icons/vsc";

import { IoRemoveOutline } from "react-icons/io5";
import { VscRemove } from "react-icons/vsc";

import { FaRegTrashAlt } from "react-icons/fa";
import CheckoutModal from "./cartmodal";
import EmptyCart from "./emptyCart";
import { toast } from "react-toastify";
import "../productPage.css";
export default function Cart() {
  const [storedBGColor, setBgColor] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [value, setValue] = useState(null);

  useEffect(() => {
    const bgColors = localStorage.getItem("bgcolor");
    console.log(bgColors);
    setBgColor(bgColors);
    //  posthog.capture("$feature_flag_called", {
    //   $feature_flag: "color-schemes",
    //   "$feature/color-schemes": bgColors,
    //   $feature_flag_response: bgColors,
    //   $override_feature_flags: bgColors,
    // });
  }, [storedBGColor]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("cart")) || [];
    setCartItems(items);
  }, []);




  const getItemValue = (coverType) => {
    if (coverType === "FullSleve") return 1;
    if (coverType === "Hoodie") return 2;
    return 0; 
  };

  console.log(cartItems);

  const incrementQuantity = (id, coverType, size) => {
    const updatedCart = cartItems.map((item) => {
      if (
        item.id === id &&
        item.coverType === coverType &&
        item.size === size
      ) {
        const newQuantity = item.quantity + 1;

        const newPrice = (item?.price / item.quantity) * newQuantity;

        return { ...item, quantity: newQuantity, price: newPrice };
      }
      return item;
    });
    setCartItems(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const decrementQuantity = (id, coverType, size) => {
    const updatedCart = cartItems.map((item) => {
      if (
        item.id === id &&
        item.coverType === coverType &&
        item.size === size
      ) {
        const newQuantity = Math.max(1, item.quantity - 1);
        const newPrice = (item?.price / item.quantity) * newQuantity;

        return { ...item, quantity: newQuantity, price: newPrice };
      }
      return item;
    });
    setCartItems(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const removeFromCart = (id, coverType, size) => {
    const updatedCart = cartItems.filter(
      (item) =>
        item.id !== id || item.coverType !== coverType || item.size !== size
    );
    setCartItems(updatedCart);

    toast.success("Item Removed from cart Successfully!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      //  theme:"dark"
      // transition: "Bounce",
    });
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  return (
    <Layout>
      <div className="w-full">
        <div className="py-4 ">
          <div className="flex gap-2  pb-3 items-center text-gray-500 ">
            <NavLink
              to="/"
              className={`flex items-center justify-center w-10 h-10 mr-4  z-1 
                ${
                  storedBGColor === "Muted_Beige"
                    ? "bg-[#0F2B36]"
                    : storedBGColor === "control"
                    ? "bg-black text-white "
                    : storedBGColor === "Soft_Gray"
                    ? "bg-[#F9744D] text-white "
                    : storedBGColor === "Light_Gray"
                    ? "bg-[#6B2055] text-white "
                    : storedBGColor === "Light_Gray_1"
                    ? "bg-[#E9185F] text-white "
                    : storedBGColor === "Cream"
                    ? "bg-[#E54C44] text-white "
                    : storedBGColor === "Charcoal_1"
                    ? "bg-[#F2C4CE] text-white "
                    : storedBGColor === "dark_shade_gary"
                    ? "bg-[#F2C4CE] text-white "
                    : storedBGColor === "Charcoals"
                    ? "bg-[#4BE8F4] text-white "
                    : storedBGColor === "Dark_Purple_Gradient"
                    ? "bg-[#4BE8F4] text-white "
                    : storedBGColor === "black_1"
                    ? "bg-[#FFFFFF] text-black "
                    : ""
                } 
                font-semibold 
                rounded-full`}
            >
              <MdKeyboardArrowLeft className="mt-0 text-3xl font-bold" />
            </NavLink>

            <NavLink
              className={`after:content-['/'] after:pl-2 capitalize font-satoshi 
                font-regular text-[20px] text-[#383838]
            ${
              storedBGColor === "Charcoal_1"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "dark_shade_gary"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "Charcoals"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "Dark_Purple_Gradient"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "black_1"
                ? "text-[#D6D6D6] opacity-85"
                : ""
            }

                
               
                `}
              to="/"
            >
              home
            </NavLink>

            <span
              className={`after:pl-2 
            capitalize font-satoshi font-regular text-[20px] text-[#383838]
            ${
              storedBGColor === "Charcoal_1"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "dark_shade_gary"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "Charcoals"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "Dark_Purple_Gradient"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "black_1"
                ? "text-[#D6D6D6] opacity-85"
                : ""
            }
         
            
            
            
            
            `}
            >
              Cart
            </span>
          </div>
          <div className="px-2">
            <div>
              <div className="">
                {cartItems?.length === 0 ? (
                  <>
                    <EmptyCart storedBGColor={storedBGColor} />
                  </>
                ) : (
                  <div className="w-full h-full lg:w-[1090px] py-8">
                    <div className="flex flex-row md:flex-row justify-between">
                      <p
                        className={`font-satoshi
                       font-regular text-[16px] md:text-[18px]
                        text-[#383838]
                        
            ${
              storedBGColor === "Charcoal_1"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "dark_shade_gary"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "Charcoals"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "Dark_Purple_Gradient"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "black_1"
                ? "text-[#D6D6D6] opacity-85"
                : ""
            }

                        
                        
                        
                        `}
                      >
                        Product
                      </p>
                      <p
                        className={`font-satoshi font-regular 
                      text-[16px] md:text-[18px] text-[#383838]
                      
                                   
            ${
              storedBGColor === "Charcoal_1"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "dark_shade_gary"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "Charcoals"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "Dark_Purple_Gradient"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "black_1"
                ? "text-[#D6D6D6] opacity-85"
                : ""
            }
                      
                      
                      `}
                      >
                        Quantity
                      </p>
                      <p
                        className={`font-satoshi font-regular
                       text-[16px] md:text-[18px] 
                       text-[#383838]
                                    
            ${
              storedBGColor === "Charcoal_1"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "dark_shade_gary"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "Charcoals"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "Dark_Purple_Gradient"
                ? "text-[#D6D6D6] opacity-85"
                : storedBGColor === "black_1"
                ? "text-[#D6D6D6] opacity-85"
                : ""
            }
                       
                       `}
                      >
                        Total
                      </p>
                    </div>
                    <hr className="border-[#383838] border-opacity-40 mt-3" />

                    <div>
                      {cartItems?.map((item) => {

                           const itemValue = getItemValue(item.coverType);


                        return (
                          // <NavLink to={`/cart/${item?.id}/${item.coverType}`}>
                          <div key={`${item?.id}-${item?.coverType}`}>
                            <div className="py-3 flex flex-col md:flex-row justify-between">
                              <div className="flex gap-4 w-full md:w-[330px] ">
                                <NavLink
                                  to={`/product/${itemValue}/${item.slug}`}
                                  onClick={(event) => event.stopPropagation()}
                                >
                                  <div className="w-[150px] h-[132px] bg-[#ffffff] rounded-sm">
                                    <img
                                      src={item.cover[0]}
                                      className="w-[150px]  h-[132px] object-contain py-2"
                                      alt={item.name}
                                    />
                                  </div>
                                </NavLink>
                                <div>
                                <NavLink
                                  to={`/product/${itemValue}/${item.slug}`}
                                  onClick={(event) => event.stopPropagation()}
                                >
                                  <p
                                    className={`font-satoshi 
                                font-bold text-[18px] 
                                md:text-[20px] text-[#091C25]
                                hover:underline
                                
                                
                                ${
                                  storedBGColor === "Muted_Beige"
                                    ? "text-[#091C25]"
                                    : storedBGColor === "control"
                                    ? "text-[#091C25]"
                                    : storedBGColor === "Soft_Gray"
                                    ? "text-[#D94E29]"
                                    : storedBGColor === "Light_Gray"
                                    ? "text-[#6B2055]"
                                    : storedBGColor === "Light_Gray_1"
                                    ? "text-[#E9185F]"
                                    : storedBGColor === "Cream"
                                    ? "text-[#E54C44]"
                                    : storedBGColor === "Charcoal_1"
                                    ? "text-[#F58F7C]"
                                    : storedBGColor === "dark_shade_gary"
                                    ? "text-[#F58F7C]"
                                    : storedBGColor === "Charcoals"
                                    ? "text-[#FE5335]"
                                    : storedBGColor === "Dark_Purple_Gradient"
                                    ? "text-[#FFFFFF]"
                                    : storedBGColor === "black_1"
                                    ? "text-[#EAA36E]"
                                    : ""
                                }  
                                
                                
                                
                                `}
                                  >
                                    {item.name}
                                  </p>
                                  </NavLink>
                                  <div className="py-1">
                                    <p
                                      className={`font-satoshi
                                   font-regular text-[14px]
                                    md:text-[16px] 
                                    text-[#091C25] 
                                    text-opacity-90
                                    
                                     ${
                                       storedBGColor === "Charcoal_1"
                                         ? "text-[#D6D6D6]"
                                         : storedBGColor === "dark_shade_gary"
                                         ? "text-[#D6D6D6]"
                                         : storedBGColor === "Charcoals"
                                         ? "text-[#D6D6D6]"
                                         : storedBGColor ===
                                           "Dark_Purple_Gradient"
                                         ? "text-[#D6D6D6] "
                                         : storedBGColor === "black_1"
                                         ? "text-[#D6D6D6]"
                                         : ""
                                     }
                                    
                                    
                                    
                                    `}
                                    >
                                      Color: White
                                    </p>
                                    <p
                                      className={`font-satoshi 
                                  font-regular text-[14px] 
                                  md:text-[16px] text-[#091C25]
                                   text-opacity-90
                                    ${
                                      storedBGColor === "Charcoal_1"
                                        ? "text-[#D6D6D6]"
                                        : storedBGColor === "dark_shade_gary"
                                        ? "text-[#D6D6D6]"
                                        : storedBGColor === "Charcoals"
                                        ? "text-[#D6D6D6]"
                                        : storedBGColor ===
                                          "Dark_Purple_Gradient"
                                        ? "text-[#D6D6D6] "
                                        : storedBGColor === "black_1"
                                        ? "text-[#D6D6D6]"
                                        : ""
                                    }
                                   
                                   
                                   
                                   `}
                                    >
                                      Size: {item.size ? item.size : "N/A"}
                                    </p>
                                    <p
                                      className={`font-satoshi 
                                  font-regular text-[14px]
                                   md:text-[16px] text-[#091C25]
                                    text-opacity-90
                                    
                                     ${
                                       storedBGColor === "Charcoal_1"
                                         ? "text-[#D6D6D6]"
                                         : storedBGColor === "dark_shade_gary"
                                         ? "text-[#D6D6D6]"
                                         : storedBGColor === "Charcoals"
                                         ? "text-[#D6D6D6]"
                                         : storedBGColor ===
                                           "Dark_Purple_Gradient"
                                         ? "text-[#D6D6D6] "
                                         : storedBGColor === "black_1"
                                         ? "text-[#D6D6D6]"
                                         : ""
                                     }
                                    
                                    
                                    
                                    `}
                                    >
                                      Type: {item.coverType}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="py-2 md:mr-24 lg:mr-24 lg:py-1 md:py-1">
                                <div className="flex gap-3">
                                  <div className="flex justify-around h-[36px] w-[100px] items-center border border-black bg-[#F2F2F3]">
                                    <VscRemove
                                      onClick={() =>
                                        decrementQuantity(
                                          item.id,
                                          item.coverType,
                                          item.size
                                        )
                                      }
                                      className={`cursor-pointer h-[14px] text-[#091C25]`}
                                    />
                                    <p
                                      className={`
                                    font-satoshi font-regular text-[16px] text-[#091C25]
                                    
                                    `}
                                    >
                                      {item.quantity}
                                    </p>
                                    <VscAdd
                                      className={`cursor-pointer h-[14px] text-[#091C25]`}
                                      onClick={() =>
                                        incrementQuantity(
                                          item.id,
                                          item.coverType,
                                          item.size
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="flex justify-center items-center">
                                    <FaRegTrashAlt
                                      className="cursor-pointer w-[16px] h-[16px] text-black"
                                      onClick={() =>
                                        removeFromCart(
                                          item.id,
                                          item.coverType,
                                          item.size
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="w-full md:w-[150px] flex justify-end mt-2 md:mt-0">
                                <span
                                  className={`font-satoshi 
                              font-regular mr-2 text-[20px] text-[#091C25] text-right
                              
                               ${
                                 storedBGColor === "Muted_Beige"
                                   ? "text-[#091C25]"
                                   : storedBGColor === "control"
                                   ? "text-[#091C25]"
                                   : storedBGColor === "Soft_Gray"
                                   ? "text-[#D94E29]"
                                   : storedBGColor === "Light_Gray"
                                   ? "text-[#411332]"
                                   : storedBGColor === "Light_Gray_1"
                                   ? "text-[#E9185F]"
                                   : storedBGColor === "Cream"
                                   ? "text-[#E54C44]"
                                   : storedBGColor === "Charcoal_1"
                                   ? "text-[#F58F7C]"
                                   : storedBGColor === "dark_shade_gary"
                                   ? "text-[#F58F7C]"
                                   : storedBGColor === "Charcoals"
                                   ? "text-[#FE5335]"
                                   : storedBGColor === "black_1"
                                   ? "text-[#EAA36E]"
                                   : storedBGColor === "Dark_Purple_Gradient"
                                   ? "text-[#D6D6D6]"
                                   : ""
                               } 
                              
                              
                              `}
                                >
                                  INR
                                </span>
                                <span
                                  className={`font-satoshi font-regular 
                              text-[20px] text-[#091C25]
                              
                              
                               ${
                                 storedBGColor === "Muted_Beige"
                                   ? "text-[#091C25]"
                                   : storedBGColor === "control"
                                   ? "text-[#091C25]"
                                   : storedBGColor === "Soft_Gray"
                                   ? "text-[#D94E29]"
                                   : storedBGColor === "Light_Gray"
                                   ? "text-[#411332]"
                                   : storedBGColor === "Light_Gray_1"
                                   ? "text-[#E9185F]"
                                   : storedBGColor === "Cream"
                                   ? "text-[#E54C44]"
                                   : storedBGColor === "Charcoal_1"
                                   ? "text-[#F58F7C]"
                                   : storedBGColor === "dark_shade_gary"
                                   ? "text-[#F58F7C]"
                                   : storedBGColor === "Charcoals"
                                   ? "text-[#FE5335]"
                                   : storedBGColor === "black_1"
                                   ? "text-[#EAA36E]"
                                   : storedBGColor === "Dark_Purple_Gradient"
                                   ? "text-[#D6D6D6]"
                                   : ""
                               } 
                              
                              `}
                                >
                                  {item.price.toLocaleString("en-IN", {
                                    maximumFractionDigits: 0,
                                  })}
                                </span>
                              </div>
                            </div>
                            <hr className="border-[#383838] border-opacity-40" />
                          </div>
                          // </NavLink>
                        );
                      })
                      // .reverse()
                      }

                      <div className="flex flex-col  md:flex-row justify-between py-2">
                        <div>
                          <NavLink
                            to="/"
                            className={`font-satoshi 
                              font-regular lg:text-[20px]
                              text-[16px] 
                              text-[#383838]
                                ${
                                  storedBGColor === "Charcoal_1"
                                    ? "text-[#D6D6D6] opacity-85"
                                    : storedBGColor === "dark_shade_gary"
                                    ? "text-[#D6D6D6] opacity-85"
                                    : storedBGColor === "Charcoals"
                                    ? "text-[#D6D6D6] opacity-85"
                                    : storedBGColor === "Dark_Purple_Gradient"
                                    ? "text-[#D6D6D6] opacity-85"
                                    : storedBGColor === "black_1"
                                    ? "text-[#D6D6D6] opacity-85"
                                    : ""
                                }
                              
                              
                              
                              `}
                          >
                            Continue Shopping
                          </NavLink>
                          <hr className="w-[140px] lg:w-[170px]  border border-[#383838] border-opacity-85" />
                        </div>
                        
                        <div className="relative py-0">
                          <p
                            className={`font-satoshi 
                          font-bold text-[20px]
                           md:text-[24px] 
                           text-[#091C25] md:ml-[-26px] flex absolute   lg:ml-[-26px]
                           
                           
                           
                             ${
                               storedBGColor === "Charcoal_1"
                                 ? "text-[#D6D6D6] opacity-85"
                                 : storedBGColor === "dark_shade_gary"
                                 ? "text-[#D6D6D6] opacity-85"
                                 : storedBGColor === "Charcoals"
                                 ? "text-[#D6D6D6] opacity-85"
                                 : storedBGColor === "Dark_Purple_Gradient"
                                 ? "text-[#D6D6D6] opacity-85"
                                 : storedBGColor === "black_1"
                                 ? "text-[#D6D6D6] opacity-85"
                                 : ""
                             }
                           
                           
                           `}
                          >
                            Subtotal
                          </p>
                        </div>
                        <div className="w-full md:w-[150px] flex justify-end">
                          <p
                            className={`font-satoshi 
                          font-bold text-[20px]
                           md:text-[24px] 
                           
                           
                        
                             ${
                               storedBGColor === "Charcoal_1"
                                 ? "text-[#D6D6D6] opacity-85"
                                 : storedBGColor === "dark_shade_gary"
                                 ? "text-[#D6D6D6] opacity-85"
                                 : storedBGColor === "Charcoals"
                                 ? "text-[#D6D6D6] opacity-85"
                                 : storedBGColor === "Dark_Purple_Gradient"
                                 ? "text-[#D6D6D6] opacity-85"
                                 : storedBGColor === "black_1"
                                 ? "text-[#D6D6D6] opacity-85"
                                 : ""
                             }
                           
                           
                           
                           
                           
                           
                           `}
                          >
                            INR{" "}
                            {cartItems
                              .reduce((total, item) => total + item.price, 0)
                              .toLocaleString("en-IN", {
                                maximumFractionDigits: 0,
                              })}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex justify-end ">
                          {/* <button className={`w-full md:w-[585px] h-[72px] 
                          
                          
                          rounded-[4px] text-center text-white font-satoshi
                           font-bold text-[20px] md:text-[24px] bg-[#0F2B36]
                           
                           ${storedBGColor==="Muted_Beige"?"bg-[#0F2B36] text-white":
                        
                        storedBGColor==="control"?"bg-[#0F2B36] text-white":
                        storedBGColor === "Soft_Gray"?"bg-[#F9744D] text-white":
                        storedBGColor === "Light_Gray"?"bg-[#6B2055] text-white":
                        storedBGColor === "Light_Gray_1"?"bg-[#E9185F] text-white":
                        storedBGColor === "Cream"?"bg-[#E54C44] text-white":
                        storedBGColor === "Charcoal_1"?"bg-[#F2C4CE] text-[#383838]":
                        storedBGColor === "dark_shade_gary"?"bg-[#F2C4CE] text-[#383838]":
                        storedBGColor === "Charcoals"?"bg-[#4BE8F4] text-[#383838]":
                        storedBGColor === "black_1"?"bg-[#FFFFFF] text-[#383838]":
                        storedBGColor === "Dark_Purple_Gradient"?"bg-[#4BE7F4] text-[#383838]":
                     


                        
                        
                        "text-white "
                      
                      }
          



                           `}>
                            Proceed to Checkout
                          </button> */}
                          <CheckoutModal storedBGColor={storedBGColor} />
                        </div>
                        <div className="flex justify-end mr-10 py-3">
                          <div className="w-full md:w-[450px]">
                            <p
                              className={`font-satoshi font-regular 
                            text-[14px] md:text-[16px] text-[#091C25]
                           
                          
                             ${
                               storedBGColor === "Charcoal_1"
                                 ? "text-[#D6D6D6] opacity-85"
                                 : storedBGColor === "dark_shade_gary"
                                 ? "text-[#D6D6D6] opacity-85"
                                 : storedBGColor === "Charcoals"
                                 ? "text-[#D6D6D6] opacity-85"
                                 : storedBGColor === "Dark_Purple_Gradient"
                                 ? "text-[#D6D6D6] opacity-85"
                                 : storedBGColor === "black_1"
                                 ? "text-[#D6D6D6] opacity-85"
                                 : ""
                             }
                           
                           
                           
                           
                           `}
                            >
                              Shipping, taxes and discount codes calculated at
                              checkout
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
