import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { RiArrowDownSLine } from "react-icons/ri";
import { GoArrowLeft } from "react-icons/go";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

// import "./cartmodal.css";

const CheckoutModal = ({ storedBGColor }) => {
  const [checkoutData, setCheckoutData] = useState({
    email: "",
    fullName: "",
    pinCode: "",
    city: "",
    state: "",
    bulidingAddress: "",
    areaAddress: "",
    landMark: "",
    payment: "",
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  // const steps = ['Contact', 'Address', 'Payment'];

  const handleNext = () => {
    setCurrentStep((prev) => (prev < 1 ? prev + 1 : prev)); //for 3 make it 3
  };

  // const handleNext = () => {
  //   let isValid = true;

  //   if (currentStep === 0) {

  //     if (!checkoutData.email) {
  //       isValid = false;

  //     }
  //   } else if (currentStep === 1) {

  //     if (!checkoutData.fullName ||
  //         !checkoutData.pinCode ||
  //         !checkoutData.city ||
  //         !checkoutData.state ||
  //         !checkoutData.bulidingAddress ||
  //         !checkoutData.areaAddress) {
  //       isValid = false;

  //     }
  //   } else if (currentStep === 2) {

  //     if (!checkoutData.payment) {
  //       isValid = false;

  //     }
  //   }

  //   if (isValid) {
  //     setCurrentStep(prevStep => prevStep + 1);
  //   } else {

  //     alert("Please fill in all required fields.");
  //   }
  // };

  const handleBack = () => {
    setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setCurrentStep(0);
  };

  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
    "Ladakh",
    "Jammu and Kashmir",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCheckoutData({
      ...checkoutData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submmitted");
    const data = {
      email: checkoutData.email,
      fullName: checkoutData.fullName,
      pinCode: checkoutData.pinCode,
      city: checkoutData.city,
      state: checkoutData.state,
      bulidingAddress: checkoutData.bulidingAddress,
      areaAddress: checkoutData.areaAddress,
      landMark: checkoutData.landMark,
      payment: checkoutData.payment,
    };
    console.log("successfully submitted", data);

    setCheckoutData({
      email: "",
      fullName: "",
      pinCode: "",
      city: "",
      state: "",
      bulidingAddress: "",
      areaAddress: "",
      landMark: "",
      payment: "",
    });
  };

  return (
    <div>
      <button
        className={`block w-[88vw] p-2 lg:w-[560px] 
          md:w-[585px] md:h-[54px] lg:h-[54px] rounded-[4px] 
          text-center  font-satoshi font-bold 
          text-[16px]  md:text-[24px] bg-[#0F2B36]
          
           ${
             storedBGColor === "Muted_Beige"
               ? "bg-[#0F2B36] text-white"
               : storedBGColor === "control"
               ? "bg-[#0F2B36] text-white"
               : storedBGColor === "Soft_Gray"
               ? "bg-[#F9744D] text-white"
               : storedBGColor === "Light_Gray"
               ? "bg-[#6B2055] text-white"
               : storedBGColor === "Light_Gray_1"
               ? "bg-[#E9185F] text-white"
               : storedBGColor === "Cream"
               ? "bg-[#E54C44] text-white"
               : storedBGColor === "Charcoal_1"
               ? "bg-[#F2C4CE] text-[#383838]"
               : storedBGColor === "dark_shade_gary"
               ? "bg-[#F2C4CE] text-[#383838]"
               : storedBGColor === "Charcoals"
               ? "bg-[#4BE8F4] text-[#383838]"
               : storedBGColor === "black_1"
               ? "bg-[#FFFFFF] text-[#383838]"
               : storedBGColor === "Dark_Purple_Gradient"
               ? "bg-[#4BE7F4] text-[#383838]"
               : "text-white "
           }
          
          
          
          
          
          `}
        onClick={openModal}
        type="button"
      >
        Proceed to Checkout
      </button>

      {isOpen && (
        <div
          id="checkout-modal"
          tabIndex="-1"
          aria-hidden={!isOpen}
          className="fixed top-0 right-0 left-0 z-10 flex justify-center items-center w-full h-full bg-black bg-opacity-50
       
          
          "
        >
          <div
            className="relative py-3 w-full h-full 
            max-w-md p-6 
          lg:max-w-[560px] 
      
          "
          >
            <div
              className="relative
              
              overflow-y-auto
             hide-scrollbar
             md:overflow-y-auto
             md:max-h-full
              lg:overflow-y-hidden
              bg-white max-h-full lg:h-[750px] rounded-[8px] 
            "
            >
              {/* lg:w-[560px] lg:h-[780px] */}

              <div className="flex items-center justify-between p-4 md:p-4   rounded-t ">
                <div className="flex gap-4 w-[200px] px-3">
                  <div className="flex gap-2  items-center text-gray-500 ">
                    <NavLink
                      className={` capitalize font-satoshi 
               text-[18px] text-[#383838]
            
             ${currentStep === 0 ? "font-bold" : " font-regular "}

                
               
                `}
                      onClick={() => setCurrentStep(0)}
                    >
                      Contact
                    </NavLink>

                    <span
                      className={` 
            capitalize font-satoshi font-regular text-[20px] text-[#383838]
           
         
            
            
            
            
            `}
                    >
                      <MdOutlineKeyboardArrowRight />
                    </span>

                    <NavLink
                      className={`  capitalize font-satoshi 
                text-[18px] text-[#383838]
            
              ${currentStep === 1 ? "font-bold" : " font-regular "}

                
               
                `}
                      onClick={() => setCurrentStep(1)}
                    >
                      Address
                    </NavLink>
                    <span
                      className={` 
            capitalize font-satoshi font-regular text-[20px] text-[#383838]
           
         
            
            
            
            
            `}
                    >
                      <MdOutlineKeyboardArrowRight />
                    </span>

                    <NavLink
                      className={` after:pl-2 capitalize font-satoshi 
                 text-[18px] text-[#383838]
                 ${currentStep === 2 ? "font-bold" : " font-regular "}
            

                
               
                `}
                      // onClick={()=>setCurrentStep(2)}
                    >
                      Payment
                    </NavLink>
                  </div>
                </div>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white mt-[0px]"
                  onClick={closeModal}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <hr className="ml-7 mr-7" />
              <div className="p-4 md:p-5">
                {currentStep === 0 && (
                  <form className=" px-2">
                    <div className=" mt-6">
                      <p
                        className={`font-satoshi font-bold text-[22px] text-[#383838]`}
                      >
                        Enter your email address to proceed
                      </p>
                      <p
                        className={`font-satoshi font-regular text-[18px] mt-1 text-[#383838]`}
                      >
                        We need your email address to send updates about the
                        order
                      </p>
                    </div>
                    <div className=" mt-1">
                      {/* <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Your email
                      </label> */}
                      <input
                        type="email"
                        name="email"
                        value={checkoutData.email}
                        onChange={handleChange}
                        // id="email"
                        className=" bg-white border focus:outline-none  border-gray-300
                          rounded-[4px] mt-3  w-full h-[54px] px-3 
                          font-satoshi font-semiBold text-opacity-40 text-[22px]"
                        placeholder="Enter Email Id"
                        required
                      />
                    </div>
                  </form>
                )}

                {currentStep === 1 && (
                  <form className="px-2">
                    <div className="mt-5 ml-[-4px] flex  gap-1">

                    <div>
                     {currentStep > 0 && (
                    // <button
                    //   type="button"
                    //   className="text-gray-700 
                    //   mb-0
                    //    space-x-2 h-[14px]"
                    //   onClick={handleBack}
                    // >
                      <SlArrowLeft 
                      omclick={handleBack}
                      className="text-black
                       cursor-pointer
                      
                      mt-[9px] h-[16px]
                      
                      "
                      onClick={handleBack}

                      /> 
                    // </button>
                  )}
                      </div> 
                      <p
                        className={`font-satoshi font-bold text-[22px] text-[#383838]`}
                      >
                     Add Delivery Address
                      </p>
                    </div>

                    <div className="">
                      <input
                        type="text"
                        name="fullName"
                        id="fullName"
                        value={checkoutData.fullName}
                        onChange={handleChange}
                        className=" bg-white border focus:outline-none  border-gray-300
                        rounded-[4px] mt-4  w-full h-[46px] px-3 
                        font-satoshi font-regular text-opacity-40 text-[22px]"
                        placeholder="Full Name"
                        required
                      />

                      <input
                        type="text"
                        name="pinCode"
                        id="pinCode"
                        value={checkoutData.pinCode}
                        onChange={handleChange}
                        className=" bg-white border focus:outline-none  border-gray-300
                        rounded-[4px] mt-4  w-full h-[46px] px-3 
                        font-satoshi font-regular text-opacity-40 text-[22px]"
                        placeholder="Pin Code"
                        required
                      />
                      {/* <div className="flex gap-3">
                        <input
                          type="text"
                          name="city"
                          value={checkoutData.city}
                          onChange={handleChange}
                          id="city"
                          className=" bg-white border focus:outline-none  border-gray-300
                        rounded-[4px] mt-4  w-full h-[46px] px-3 
                        font-satoshi font-regular text-opacity-40 text-[22px]"
                          placeholder="City"
                          required
                        />
                        <select
                          name="state"
                          value={checkoutData.state}
                          onChange={handleChange}
                          id="state"
                          className="bg-white border focus:outline-none border-gray-300
                 rounded-[4px] mt-4 w-full h-[46px] px-2 
                 font-satoshi font-regular text-opacity-40 text-[22px]"
                          required
                        >
                          <option value="" disabled className=" font-satoshi font-regular text-[22px]  text-gray-200 ">
                         State
                          </option>
                          {indianStates?.map((state) => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))}
                        </select>
                      </div> */}

                      <div className="flex gap-3">
                        <input
                          type="text"
                          name="city"
                          value={checkoutData.city}
                          onChange={handleChange}
                          id="city"
                          className="bg-white border 
    focus:outline-none border-gray-300 
    rounded-[4px] mt-4 w-full lg:w-52 h-[46px] px-3 font-satoshi font-regular text-opacity-40 text-[22px]"
                          placeholder="City"
                          required
                        />
                        <div className="relative w-full">
                          <select
                            name="state"
                            value={checkoutData.state}
                            onChange={handleChange}
                            id="state"
                            className="bg-white border focus:outline-none
       border-gray-300 rounded-[4px] mt-4 w-full h-[46px] 
       px-3
       font-satoshi font-regular text-[22px] text-black 
       appearance-none pr-10"
                            required
                            style={{
                              color: checkoutData.state ? "#000000" : "#9CA3AF",
                            }}
                          >
                            <option value="" disabled className="text-gray-400">
                              State
                            </option>
                            {indianStates?.map((state) => (
                              <option
                                key={state}
                                value={state}
                                className="text-black"
                              >
                                {state}
                              </option>
                            ))}
                          </select>
                          <RiArrowDownSLine
                            className="absolute top-[30px] right-2 
       text-gray-500
        pointer-events-none"
                            size={20}
                          />
                        </div>
                      </div>

                      <input
                        type="text"
                        name="bulidingAddress"
                        value={checkoutData.bulidingAddress}
                        onChange={handleChange}
                        id="bulidingAddress"
                        className=" bg-white border focus:outline-none  border-gray-300
                        rounded-[4px] mt-4  w-full h-[46px] px-3 
                        font-satoshi font-regular text-opacity-40 text-[22px]"
                        placeholder="House no / Building / Apartment"
                        required
                      />
                      <input
                        type="text"
                        name="areaAddress"
                        value={checkoutData.areaAddress}
                        onChange={handleChange}
                        id="areaAddress"
                        className=" bg-white border focus:outline-none  border-gray-300
                        rounded-[4px] mt-4  w-full h-[46px] px-3 
                        font-satoshi font-regular text-opacity-40 text-[22px]"
                        placeholder="Area, Sector, Village, Street"
                        required
                      />
                      <input
                        type="text"
                        name="landMark"
                        value={checkoutData.landMark}
                        onChange={handleChange}
                        id="landMark"
                        className=" bg-white border focus:outline-none  border-gray-300
                        rounded-[4px] mt-4  w-full h-[46px] px-3 
                        font-satoshi font-regular text-opacity-40 text-[22px]"
                        placeholder="Landmark (optional)"
                        // required
                      />
                    </div>
                  </form>
                )}

                {/* {currentStep === 2 && (
                  <form className="space-y-4 px-2">
                    <div >
                     
                      <input
                        type="text"
                        name="payment"
                        value={checkoutData.payment}
                        onChange={handleChange}
                        id="payment"
                        className=" bg-white border focus:outline-none  border-gray-300
                        rounded-[4px] mt-4  w-full h-[46px] px-3 
                        font-satoshi font-regular text-opacity-40 text-[22px]"
                        placeholder="Card number"
                        required
                      />
                    </div>
                  </form>
                )} */}

                <div className="py-4 mt-2 px-2">
                  {currentStep < 2 ? (
                    <button
                      type="button"
                      className="w-full text-white   focus:outline-none bg-[#0F2B36] h-[54px] rounded-[4px] text-[22px] font-satoshi font-semiBold px-4 py-2"
                      onClick={handleNext}
                    >
                      Continue
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="w-full text-white   focus:outline-none bg-[#0F2B36] h-[54px] rounded-[4px] text-[22px] font-satoshi font-semiBold px-4 py-2"
                      onClick={(e) => {
                        handleSubmit(e);
                        // closeModal()
                      }}
                    >
                      Submit
                    </button>
                  )}

                  {/* {currentStep > 0 && (
                    <button
                      type="button"
                      className="text-gray-700  px-2  flex items-center justify-self-start py-6
                      
                       raeltive space-x-2"
                      onClick={handleBack}
                    >
                      <GoArrowLeft /> <span>Back</span>
                    </button>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckoutModal;
