import React from "react";

import { MdRemoveShoppingCart } from "react-icons/md";
import { NavLink } from "react-router-dom";

export default function EmptyCart({ storedBGColor }) {
  return (
    <div>
      <div className="flex flex-col justify-center items-center h-[76vh]">
        <div>
          <MdRemoveShoppingCart
            className={`text-9xl w-full mt-0
                
                 ${
                   storedBGColor === "Muted_Beige"
                     ? "text-[#091C25] text-opacity-50"
                     : storedBGColor === "control"
                     ? "text-[#091C25] text-opacity-50 "
                     : storedBGColor === "Soft_Gray"
                     ? "text-[#D94E29] text-opacity-50"
                     : storedBGColor === "Light_Gray"
                     ? "text-[#6B2055] text-opacity-50"
                     : storedBGColor === "Light_Gray_1"
                     ? "text-[#E9185F] text-opacity-50"
                     : storedBGColor === "Cream"
                     ? "text-[#E54C44] text-opacity-50"
                     : storedBGColor === "Charcoal_1"
                     ? "text-[#F58F7C] text-opacity-50"
                     : storedBGColor === "dark_shade_gary"
                     ? "text-[#F58F7C] text-opacity-50"
                     : storedBGColor === "Charcoals"
                     ? "text-[#FE5335] text-opacity-50"
                     : storedBGColor === "Dark_Purple_Gradient"
                     ? "text-[#FFFFFF] text-opacity-50"
                     : storedBGColor === "black_1"
                     ? "text-[#EAA36E] text-opacity-50"
                     : ""
                 }  
                
                
                
                `}
          />
        </div>
        <div>
          <p
            className={`text-[22px] text-center
                font-satoshi font-semiBold 
                ${
                  storedBGColor === "Charcoal_1"
                    ? "text-[#D6D6D6]"
                    : storedBGColor === "dark_shade_gary"
                    ? "text-[#D6D6D6]"
                    : storedBGColor === "Charcoals"
                    ? "text-[#D6D6D6]"
                    : storedBGColor === "Dark_Purple_Gradient"
                    ? "text-[#D6D6D6] "
                    : storedBGColor === "black_1"
                    ? "text-[#D6D6D6]"
                    : ""
                }


                `}
          >
            Your Cart is Empty
          </p>
        </div>
        <div className="mt-4">
          <NavLink to={"/"}>
            <button
              className={`
               
                  w-[290px]
                  h-[54px]
                 
                  rounded-[4px]
                  font-satoshi
            
                  font-bold
                  text-[24px]                  
                  

                  ${
                    storedBGColor === "Muted_Beige"
                      ? "bg-[#0F2B36] text-white"
                      : storedBGColor === "control"
                      ? " bg-[#0F2B36] text-white"
                      : storedBGColor === "Soft_Gray"
                      ? "bg-[#F9744D] text-white"
                      : storedBGColor === "Light_Gray"
                      ? "bg-[#6B2055] text-white"
                      : storedBGColor === "Light_Gray_1"
                      ? "bg-[#E9185F] text-white"
                      : storedBGColor === "Cream"
                      ? "bg-[#E54C44] text-white"
                      : storedBGColor === "Charcoal_1"
                      ? "bg-[#F2C4CE] text-[#383838]"
                      : storedBGColor === "dark_shade_gary"
                      ? "bg-[#F2C4CE] text-[#383838]"
                      : storedBGColor === "Charcoals"
                      ? "bg-[#4BE8F4] text-[#383838]"
                      : storedBGColor === "black_1"
                      ? "bg-[#FFFFFF] text-[#383838]"
                      : storedBGColor === "Dark_Purple_Gradient"
                      ? "bg-[#4BE7F4] text-[#383838]"
                      : "text-white "
                  } 



flex justify-center items-center



                  
                  `}
            >
              Continue Shopping
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
