import React, { useEffect, useRef, useState } from "react";
import Layout from "../layouts/layout";
import ProductCard from "../components/productCard";
import { products } from "../data/productData";
import { motion, AnimatePresence } from "framer-motion";
import Prints from "./prints";
import { NavLink } from "react-router-dom";
import TshirtProductCard from "../components/tshirtProductCard";
import FullSleeproductCard from "../components/fullSleveProdctCard";
import HoodieProductCard from "../components/hoodieProductData";
import { BsCart4 } from "react-icons/bs";
// import ScrollContent from './scroll'

// import Demo1 from './Demos/Demo1/Demo1'
const HomePage = () => {
  const [storedBGColor, setBgColor] = useState(null);
  const scrollContainerRef = useRef(null);
  const [productDisplay, setProdutDisplay] = useState("best");

  const hanaleTshirtProduct = () => {
    setProdutDisplay("tshirts");
  };
  const handleFullSleveProduct = () => {
    setProdutDisplay("fullsleve");
  };

  const handleHoodieProduct = () => {
    setProdutDisplay("hoodie");
  };

  const handleBestProduct = () => {
    setProdutDisplay("best");
  };

  useEffect(() => {
    const bgColors = localStorage.getItem("bgcolor");
    console.log(bgColors);
    setBgColor(bgColors);
  }, [storedBGColor]);

  // useEffect(() => {
  //   const container = scrollContainerRef.current;
  //   // const onWheel = (event) => {
  //   //   event.preventDefault();
  //   //   container.scrollLeft += event.deltaY; // Moves the scroll horizontally
  //   // };

  //   const onWheel = (event) => {
  //     event.preventDefault();
  //     container.scrollBy({
  //       left: event.deltaY, // Adjust horizontal scroll
  //       behavior: "smooth", // Smooth scrolling
  //     });
  //   };
  //   container.addEventListener("wheel", onWheel);

  //   return () => container.removeEventListener("wheel", onWheel);
  // }, []);

  return (
    <>
    <Layout>
      <div className="lg:py-4">
        <div className=" flex flex-wrap lg:justify-between lg:items-center ">
          <div>
            <h1
              className={`text-[#383838] font-black font-satoshi text-[40px] tracking-tight leading-[54px] mb-2
          ${
            storedBGColor === "Cream"
              ? "text-[#E54C44]"
              : storedBGColor === "Light_Gray"
              ? "text-[#6B2055]"
              : storedBGColor === "Light_Gray_1"
              ? "text-[#E9185F]"
              : storedBGColor === "control"
              ? "text-[#383838]"
              : storedBGColor === "Soft_Gray"
              ? "text-[#D94E29]"
              : storedBGColor === "Muted_Beige"
              ? "text-[#091C25]"
              : storedBGColor === "Charcoal_1"
              ? "text-[#F58F7C]"
              : storedBGColor === "dark_shade_gary"
              ? "text-[#F58F7C]"
              : storedBGColor === "Charcoals"
              ? "text-[#FE5335]"
              : storedBGColor === "Dark_Purple_Gradient"
              ? "text-[#FFFFFF]"
              : storedBGColor === "black_1"
              ? "text-[#EAA36E]"
              : ""
          }
          
          `}
            >
              Astro Shop To React Shop
            </h1>
            <div className="mt-[-4px]">
              <h3
                className={`1text-[#383838] font-satoshi font-regular 
      text-[20px] mb-4
      
       ${
         storedBGColor === "Charcoal_1"
           ? "text-[#D6D6D6]"
           : storedBGColor === "dark_shade_gary"
           ? "text-[#D6D6D6]"
           : storedBGColor === "Charcoals"
           ? "text-[#D6D6D6]"
           : storedBGColor === "Dark_Purple_Gradient"
           ? "text-[#D6D6D6] "
           : storedBGColor === "black_1"
           ? "text-[#D6D6D6]"
           : ""
       }
      
      
      
      
      
      `}
              >
                Shop a 100% Organic cotton T-shirts at affordable rates!
              </h3>
            </div>
          </div>
          <NavLink
            to="/cart"
            className={`flex items-center mt-[-26px] px-4 h-8 rounded-md   z-1 
              ${
                storedBGColor === "Muted_Beige"
                  ? "bg-[#0F2B36] text-white transition-all ease-linear duration-100 hover:bg-white hover:text-[#0F2B36] hover:border hover:border-[#0F2B36]"
                  : storedBGColor === "control"
                  ? "bg-black text-white transition-all ease-linear duration-100 hover:bg-white hover:text-black hover:border hover:border-black"
                  : storedBGColor === "Soft_Gray"
                  ? "bg-[#F9744D] text-white transition-all ease-linear duration-100 hover:bg-white hover:text-[#F9744D] hover:border hover:border-[#F9744D]"
                  : storedBGColor === "Light_Gray"
                  ? "bg-[#6B2055] text-white transition-all ease-linear duration-100 hover:bg-white hover:text-[#6B2055] hover:border hover:border-[#6B2055]"
                  : storedBGColor === "Light_Gray_1"
                  ? "bg-[#E9185F] text-white transition-all ease-linear duration-100 hover:bg-white hover:text-[#E9185F] hover:border hover:border-[#E9185F]"
                  : storedBGColor === "Cream"
                  ? "bg-[#E54C44] text-white transition-all ease-linear duration-100 hover:bg-white hover:text-[#E54C44] hover:border hover:border-[#E54C44]"
                  : storedBGColor === "Charcoal_1"
                  ? "bg-[#F2C4CE] text-white transition-all ease-linear duration-100 hover:bg-white hover:text-[#F2C4CE] hover:border hover:border-[#f2abbb]"
                  : storedBGColor === "dark_shade_gary"
                  ? "bg-[#F2C4CE] text-white transition-all ease-linear duration-100 hover:bg-white hover:text-[#F2C4CE] hover:border hover:border-[#F2C4CE]"
                  : storedBGColor === "Charcoals"
                  ? "bg-[#4BE8F4] text-white transition-all ease-linear duration-100 hover:bg-white hover:text-[#4BE8F4] hover:border hover:border-[#4BE8F4]"
                  : storedBGColor === "Dark_Purple_Gradient"
                  ? "bg-[#4BE8F4] text-white transition-all ease-linear duration-100 hover:bg-white hover:text-[#4BE7F4] hover:border hover:border-[#4BE7F4]"
                  : storedBGColor === "black_1"
                  ? "bg-slate-200 text-black transition-all ease-linear duration-100 hover:bg-gray-400 hover:text-black hover:border hover:border-black"
                  : ""
              }
                font-semibold 
                `}
          >
            {/* <MdKeyboardArrowLeft className="mt-0 text-3xl font-bold" /> */}
            <BsCart4 className=" text-[20px] mr-1 " /> Cart
          </NavLink>
        </div>
        <div className="py-4">
          <NavLink
            className={`text-[#A5A5A5]  font-satoshi text-[20px] 
        
         ${
           storedBGColor === "Charcoal_1"
             ? "text-[#D6D6D6]"
             : storedBGColor === "dark_shade_gary"
             ? "text-[#D6D6D6]"
             : storedBGColor === "Charcoals"
             ? "text-[#D6D6D6]"
             : storedBGColor === "Dark_Purple_Gradient"
             ? "text-[#D6D6D6] "
             : storedBGColor === "black_1"
             ? "text-[#D6D6D6]"
             : ""
         }
         ${productDisplay === "best" ? "font-bold underline text-black" : ""}
        
        `}
            onClick={handleBestProduct}
            style={{ lineHeight: "27px" }}
          >
            Bestsellers
          </NavLink>
          <span className="text-[#A5A5A5]  text-[20px] mx-2">&#183;</span>
          <NavLink
            className={`text-[#A5A5A5]  font-satoshi text-[20px] 
         
         ${
           storedBGColor === "Cream"
             ? "hover:text-[#E54C44] hover:underline"
             : storedBGColor === "Light_Gray"
             ? "hover:text-[#6B2055] hover:underline"
             : storedBGColor === "Light_Gray_1"
             ? "hover:text-[#E9185F] hover:underline"
             : storedBGColor === "control"
             ? "hover:text-[#383838] hover:underline"
             : storedBGColor === "Soft_Gray"
             ? "hover:text-[#D94E29] hover:underline"
             : storedBGColor === "Muted_Beige"
             ? "hover:text-[#091C25] hover:underline"
             : storedBGColor === "Charcoal_1"
             ? "hover:text-[#F58F7C] hover:underline"
             : storedBGColor === "dark_shade_gary"
             ? "hover:text-[#F58F7C] hover:underline"
             : storedBGColor === "Charcoals"
             ? " hover:underline hover:text-[#FE5335]"
             : storedBGColor === "Dark_Purple_Gradient"
             ? "hover:text-[#FFFFFF] hover:underline"
             : storedBGColor === "black_1"
             ? "hover:text-[#EAA36E] hover:underline"
             : "font-regular"
         }
         
         ${productDisplay === "tshirts" ? "font-bold underline text-black" : ""}
         `}
            onClick={hanaleTshirtProduct}
          >
            Tshirts
          </NavLink>
          <span className="text-[#A5A5A5]  text-[20px] mx-2 ">&#183;</span>
          <NavLink
            to="/"
            className={`text-[#A5A5A5]  font-satoshi text-[20px] 
      tracking-tight
         
         ${
           storedBGColor === "Cream"
             ? "hover:text-[#E54C44] hover:underline"
             : storedBGColor === "Light_Gray"
             ? "hover:text-[#6B2055] hover:underline"
             : storedBGColor === "Light_Gray_1"
             ? "hover:text-[#E9185F] hover:underline"
             : storedBGColor === "control"
             ? "hover:text-[#383838] hover:underline"
             : storedBGColor === "Soft_Gray"
             ? "hover:text-[#D94E29] hover:underline"
             : storedBGColor === "Muted_Beige"
             ? "hover:text-[#091C25] hover:underline"
             : storedBGColor === "Charcoal_1"
             ? "hover:text-[#F58F7C] hover:underline"
             : storedBGColor === "dark_shade_gary"
             ? "hover:text-[#F58F7C] hover:underline"
             : storedBGColor === "Charcoals"
             ? " hover:underline hover:text-[#FE5335]"
             : storedBGColor === "Dark_Purple_Gradient"
             ? "hover:text-[#FFFFFF] hover:underline"
             : storedBGColor === "black_1"
             ? "hover:text-[#EAA36E] hover:underline"
             : "font-regular"
         }
         
          ${
            productDisplay === "fullsleve"
              ? "font-bold underline text-black"
              : ""
          }
         `}
            onClick={handleFullSleveProduct}
          >
            Full Sleeve Tshirt
          </NavLink>
          <span className="text-[#A5A5A5]  text-[20px] mx-2">&#183;</span>
          <NavLink
            to="/"
            className={`text-[#A5A5A5]  font-satoshi text-[20px] 
         
        ${
          storedBGColor === "Cream"
            ? "hover:text-[#E54C44] hover:underline"
            : storedBGColor === "Light_Gray"
            ? "hover:text-[#6B2055] hover:underline"
            : storedBGColor === "Light_Gray_1"
            ? "hover:text-[#E9185F] hover:underline"
            : storedBGColor === "control"
            ? "hover:text-[#383838] hover:underline"
            : storedBGColor === "Soft_Gray"
            ? "hover:text-[#D94E29] hover:underline"
            : storedBGColor === "Muted_Beige"
            ? "hover:text-[#091C25] hover:underline"
            : storedBGColor === "dark_shade_gary"
            ? "hover:text-[#F58F7C] hover:underline"
            : storedBGColor === "Charcoal_1"
            ? "hover:text-[#F58F7C] hover:underline"
            : storedBGColor === "Charcoals"
            ? " hover:underline hover:text-[#FE5335]"
            : storedBGColor === "Dark_Purple_Gradient"
            ? "hover:text-[#FFFFFF] hover:underline"
            : storedBGColor === "black_1"
            ? "hover:text-[#EAA36E] hover:underline"
            : "font-regular"
        }
         ${productDisplay === "hoodie" ? "font-bold underline text-black" : ""}
        
        `}
            onClick={handleHoodieProduct}
          >
            Hoodie
          </NavLink>
          {/* <NavLink to={'/prints'}>
            prints
          </NavLink> */}
        </div>
      </div>


     

      <div className="grid grid-cols-1   sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-8 w-full">
      

        {products.slice(0,4).map((product) => (
          <motion.div
            key={product.id}
            initial={{ opacity: 0, y: 60 }}
            animate={{ opacity: 1, y: -10 }}
            exit={{ opacity: 0, y: -90 }}
            transition={{ duration: 0.5 }}
          >
            {productDisplay === "tshirts" ? (
              <TshirtProductCard product={product} />
            ) : productDisplay === "fullsleve" ? (
              <FullSleeproductCard product={product} />
            ) : productDisplay === "hoodie" ? (
              <HoodieProductCard product={product} />
            ) : productDisplay === "best" ? (
              <ProductCard product={product} />
            ) : (
              <ProductCard product={product} />
            )}
          </motion.div>
        ))}
      </div>

      
      <div className=" py-6" >
{/* <ScrollContent/> */}
{/* <Demo1 images={images}/> */}

</div>


<Prints storedBGColor={storedBGColor}/>
     
    </Layout>
    </>
  );
};

export default HomePage;
