import React, { Suspense, useEffect, useState } from "react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import Layout from "../layouts/layout";

import {images,images1,images2,images3,images4} from '../data/animimagesData'
import PositionDesign1 from "../assets/printsAssets/t-shirtprintpositionAssest/Group 18.png";
import PositionDesign2 from "../assets/printsAssets/t-shirtprintpositionAssest/Group 18.png";
import PositionDesign3 from "../assets/printsAssets/t-shirtprintpositionAssest/Group 19.png";

// import DemoComp1 from './animComp1/Demo1.js'


const PrintsDemo = React.lazy(() => import("./printsDemo"));
const Demo1 = React.lazy(() => import("./Demos/Demo1/Demo1"));
const Demo2 = React.lazy(() => import("./Demos/Demo2/Demo2"));
const Demo3 = React.lazy(() => import("./Demos/Demo3/Demo3"));
const Demo4 = React.lazy(() => import("./Demos/Demo4/Demo4"));
const Demo5 = React.lazy(() => import("./Demos/Demo5/Demo5"));
const AnimDemo1 = React.lazy(()=>import('./animComp/anim1'))
const AnimDemo2 = React.lazy(()=>import('./animComp/anim2'))
const AnimDemo3 = React.lazy(()=>import('./animComp/anim3'))
const AnimDemo4 = React.lazy(()=>import('./animComp/anim4'))




const designPositionImages = [
  PositionDesign1,
  PositionDesign2,
  PositionDesign3,
];












const PrintsGallery = ({ storedBGColor }) => {
  const [printDemos, setPrintDemos] = useState("");
  const [printDisplays, setPrintDisplays] = useState("");



  
  useEffect(() => {
    const prints = localStorage.getItem("printsDemo");
    if (!prints) {
      const printArray = [
        "control",
        "printDemo1",
        "printDemo2",
        "printDemo3",
        "printDemo4",
        "printDemo5",
        "printDemo6",
        "printDemo7",
        "printDemo8",
        "printDemo9",
        
      ];
      const randomPrint = Math.floor(Math.random() * printArray.length);
      setPrintDemos(printArray[randomPrint]);
      localStorage.setItem("printsDemo", printArray[randomPrint]);
    } else {
      setPrintDemos(prints);
    }
  }, []);

  useEffect(() => {
    const printDisplayFunction = (type) => {
      switch (type) {
        case "control":
          return (
            <PrintsDemo
              images={images.length > 0 ? images : ""}
              storedBGColor={storedBGColor}
              designPositionImages={designPositionImages}
            />
          );
        case "printDemo1":
          return( <Demo1 images={images.length > 0 ? images : ""}   
            storedBGColor={storedBGColor} />
          )
        case "printDemo2":
          return(
             <Demo2 images={images.length > 0 ? images : ""}   storedBGColor={storedBGColor}/>
        
                  )        
      case "printDemo3":
          return (<Demo3 
            images={images.length > 0 ? images : ""}  storedBGColor={storedBGColor}/>)
        case "printDemo4":
          return (<Demo4 
            images={images.length > 0 ? images : ""}  storedBGColor={storedBGColor}/>)
        case "printDemo5":
          return (
          <Demo5 images={images.length > 0 ? images : ""} 
          storedBGColor={storedBGColor}
          
          />)
        case "printDemo6":
          return <AnimDemo1 images={images1.length>0?images1:""}/>;
        case "printDemo7":
          return <AnimDemo2 images={images2.length>0?images2:""}/>;
        case "printDemo8":
          return  <AnimDemo3 images={images3?.length>0?images3:""}/>;
        case "printDemo9":
          return <AnimDemo4 images={images4?.length>0?images4:""}/>;
        default:
          return (
            <PrintsDemo
              images={images.length > 0 ? images : ""}
              storedBGColor={storedBGColor}
              designPositionImages={designPositionImages}
            />
          );
      }
    };
    const demo = printDisplayFunction(printDemos);
    setPrintDisplays(demo);
    const printAnimation = localStorage.getItem("printsDemo");
  }, [printDemos]);

  return (
    <div >
      <Suspense fallback={<div>Loading...</div>}>
        {/* <Demo5 images={images.length>0?images:""}/> */}

        {printDisplays}
        {/* <AnimDemo1 images={images1.length>0?images1:""}/> */}
        {/* <AnimDemo2 images={images2.length>0?images2:""}/> */}
       {/* <AnimDemo3 images={images3?.length>0?images3:""}/> */}
       {/* <AnimDemo4 images={images4?.length>0?images4:""}/> */}

        {/* <DemoComp1/> */}

        {/* <PrintsDemo
        images={images.length>0?images:""}
        storedBGColor={storedBGColor} 
        designPositionImages={designPositionImages}
        /> */}
      </Suspense>
    </div>
  );
};

export default PrintsGallery;
